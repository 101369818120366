 
import { Redirect, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAuthState, useAuthDispatch, logout } from '../context'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
 
    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const timeout = 3600000
    const [remaining, setRemaining] = useState(timeout)
    const [elapsed, setElapsed] = useState(0)
    const [lastActive, setLastActive] = useState(+new Date())
    const [isIdle, setIsIdle] = useState(false)

    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => {
        
        setIsIdle(true)
        Swal.fire({
            title: 'Logout!',
            text: 'You are logged out, please sign in again.',
            confirmButtonColor: '#FDC20F',
            confirmButtonText: 'Log In',
            confirmButtonTextColor: '#000',
            allowOutsideClick: false,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then((result) => {
            if (result.isConfirmed) {
                logout(dispatch);
                window.location.href = '/login';
            }
          })
    }

    const {
        reset,
        pause,
        resume,
        getRemainingTime,
        getLastActiveTime,
        getElapsedTime
    } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle
    })

    const handleReset = () => reset()
    const handlePause = () => pause()
    const handleResume = () => resume()

    useEffect(() => {
        setRemaining(getRemainingTime())
        setLastActive(getLastActiveTime())
        setElapsed(getElapsedTime())

        setInterval(() => {
            setRemaining(getRemainingTime())
            setLastActive(getLastActiveTime())
            setElapsed(getElapsedTime())
        }, 1000)
    }, [])

    

    return (
        <Route
            path={path}
            render={props =>
                isPrivate && !Boolean(userDetails.token) ? (
                    <Redirect
                        to={{ pathname: "/login" }}
                    />
                ) : (   <>                        
                        <Component {...props} />
                        </>
                    )
            }
            {...rest}
        />
    )
}
 
export default AppRoutes