import AddPost from "../pages/addPost";
import Dashboard from "../pages/dashboard";
import Profile from "../pages/profile";
import Home from "../pages/home";
import Login from "../pages/loginNew";
import Register from "../pages/register";
import ForgotPwd from "../pages/forgotpwd";
import ResetPwdConfirmation from "../pages/resetpwdconfirmation";
import ResetPwd from "../pages/resetpwd";
import Project from "../pages/project";
import ProjectAssets from "../pages/projectAssets";
import ProjectParameters from "../pages/projectParameters";
import ProjectEditOverview from "../pages/projectEditOverview";
import ProjectEditUploadResults from "../pages/projectEditUploadResults";
import ProjectEditUploadAssets from "../pages/projectEditUploadAssets";
import ProjectViewAssets from "../pages/projectEditViewAssets";
import Project3DViewer from "../pages/project3Dviewer";
// import Project3DViewer4 from "../pages/project3Dviewer4";
import EditProjectDetails from "../pages/projectEditDetails";
import projectDeliverables from "../pages/projectDeliverables";
import ProjectInvites from "../pages/projectInvites";
import ProjectDetails from "../pages/projectDetails";
import ArenMembers from "../pages/members/index";
import ArenCreateMembers from "../pages/members/member";
import ArenEditMembers from "../pages/members/member-edit";
import ConfigDeliverables from "../pages/projectEditConfigureDeliverables";
import CreateOrtho from "../pages/projectCreateConfigureDeliverables";
import CreateOrthoImg from "../pages/projectCreateConfigureDeliverablesUploadOrtho";
import CreateOrthoDamages from "../pages/projectCreateConfigureDeliverablesUploadDamages";
import CreateOrthoDamagesImages from "../pages/projectCreateConfigureDeliverablesUploadDamagesImages";
import CreateOrthoDetails from "../pages/projectEditConfigureDeliverablesDetails";
import CreateOrthoAllDamagesImage from "../pages/projectCreateConfigureDeliverablesUploadAllDamagesImages";
import ProjectSettings from "../pages/projectSettings";
// import ProjectLabeling from "../pages/projectLabeling";

const routes =[
    
    {
      path:'/',
      component: Login,
      isPrivate: false,
    },
    {
        path:'/login',
        component: Login,
        isPrivate: false,
    },
    {
        path:'/forgotpassword',
        component: ForgotPwd,
        isPrivate: false,
    },
    {
        path:'/forgotpasswordconfirmation',
        component: ResetPwdConfirmation,
        isPrivate: false,
    },
    {
        path:'/resetpwd/:token',
        component: ResetPwd,
        isPrivate: false,
    },
    {
        path:'/register',
        component: Register,
        isPrivate: false,
    },
    {
        path:'/dashboard',
        component: Dashboard,
        isPrivate: true,
    },
    {
        path:'/profile',
        component: Profile,
        isPrivate: true,    
    },
    {
        path:'/project',
        component: Project,
        isPrivate: true,
    },
    {
        path:'/project/:id/assets',
        component: ProjectAssets,
        isPrivate: true,
    },
    {
        path:'/project/:id/parameters',
        component: ProjectParameters,
        isPrivate: true,
    },
    {
        path:'/project/:id/edit',
        component: ProjectEditOverview,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewer',
        component: Project3DViewer,
        isPrivate: true,
    },
    // {
    //     path:'/project/:id/viewer-model',
    //     component: Project3DViewer4,
    //     isPrivate: true,
    // },
    {
        path:'/project/:id/edit/details',
        component: EditProjectDetails,
        isPrivate: true,
    },
    {
        path:'/project/:id/deliverable',
        component: projectDeliverables,
        isPrivate: true,
    },
    {
        path:'/project/:id/viewassets',
        component: ProjectViewAssets,
        isPrivate: true,
    },
    {
        path:'/project/:id/uploadresults',
        component: ProjectEditUploadResults,
        isPrivate: true,
    },
    {
        path:'/project/:id/settings',
        component: ProjectSettings,
        isPrivate: true,
    },
    {
        path:'/project/:id/uploadassets',
        component: ProjectEditUploadAssets,
        isPrivate: true,
    },
    {
        path:'/project/:id/invites',
        component: ProjectInvites,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables',
        component: ConfigDeliverables,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/create',
        component: CreateOrtho,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/:orthoId/upload',
        component: CreateOrthoImg,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/:orthoId/damages',
        component: CreateOrthoDamages,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/:orthoId/damages/images',
        component: CreateOrthoDamagesImages,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/:orthoId/damages/allimage',
        component: CreateOrthoAllDamagesImage,
        isPrivate: true,
    },
    {
        path:'/project/:id/configDeliverables/:orthoId/details',
        component: CreateOrthoDetails,
        isPrivate: true,
    },
    {
        path:'/project/:id/details',
        component: ProjectDetails,
        isPrivate: true,
    },
    // {
    //     path:'/project/:id/labeling',
    //     component: ProjectLabeling,
    //     isPrivate: true,
    // },
    {
        path:'/aren-members',
        component: ArenMembers,
        isPrivate: true,
    },
    {
        path:'/aren-members/create',
        component: ArenCreateMembers,
        isPrivate: true,
    },
    {
        path:'/aren-members/:id/edit',
        component: ArenEditMembers,
        isPrivate: true,
    }
    
    
  ]
   
  export default routes
