import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Form,
  Modal,
  Table
} from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../components/header";
import addInvite from "../images/add-invite.svg";
import addIconButton from "../images/addIconButton.svg";
import profilePlaceholder from "../images/user-profile-tools.svg";
import chevronImg from "../images/chevron.svg";
import deleteInviteImg from "../images/deleteInvite.svg";
import assetPlaceholder from "../images/projectDetails.svg";
import deleteImg from "../images/delete.svg";
import { API_URL, ASSET_DIR, RESULT_DIR } from "../constants";
import { useAuthState, useAuthDispatch, logout } from "../context";
import ProjectMenu from "../components/projectMenu";
import Moment from "moment";
import Thumbnail from "../components/thumbnails";
import FolderName from "../components/renderfolder";
import downloadIcon from "../images/downloadarrow.svg";
import { saveAs } from "../library/saveascustom";
import EditDetails from "../components/EditDetails";
import TopButtons from "../components/topButtons";
import { toLength } from "lodash";
import Dropzone from "../components/dropzone";

import ReactDOM from "react-dom";
import FolderTree from "react-folder-tree";
import useTreeState, { testData } from "use-tree-state";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BuildIcon from '@mui/icons-material/Build';
import ModeEditIcon from '@mui/icons-material/ModeEdit';



const { uuid } = require("uuidv4");

const ConfigDeliverables = (props) => {
  let { id, orthoId } = useParams();
  const [projectId, setProjectId] = useState("");
  const [projectInvites, setProjectInvites] = useState([]);
  const [isProjectGuest, setIsProjectGuest] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [invite, setInvite] = useState("");
  const handleShow = () => setShow(true);
  const [project, setProject] = useState([]);
  const [assets, setAssets] = useState([]);
  const [niraid, setNiraid] = useState([]);
  const [status, setStatus] = useState([]);

  const [folderName, setFolderName] = useState("");
  const [folderLevel, setFolderLevel] = useState(0);
  const [folderType, setFolderType] = useState("");
  const [folderId, setFolderId] = useState(""); // parent folderId
  const [parentFolderName, setParentFolderName] = useState("");
  const [hasAssetId, setHasAssetId] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [assetName, setAssetName] = useState("");
  const [isOrthoUpload, setIsOrthoUpload] = useState("");
  const [isCsvProcess, setIsCsvProcess] = useState("");

  const [expanded, setExpanded] = useState([]);
  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const [showEditFolder, setShowEditFolder] = useState(false);
  const [folderEditName, setFolderEditName] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [defaultFolderName, setDefaultFolderName] = useState("");

  const [ortho, setOrtho] = useState([]);


  const handleLogout = () => {
    window.location.href = "/login";
  };

  const handleEdit = (id) => {
    window.location.href = `/project/${id}/edit/details`;
  };
  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });
  useEffect(() => {
    setProjectId(id);
    async function getProjectById() {
      const data_obj = {
        projectid: id,
      };
      console.log("data_obj", data_obj);
      const config = {
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
      };
      const url = `${API_URL}/projects/getProjectById`;

      let hxr = await axios(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: data_obj,
      });
      setProject(hxr.data.project);
      setIsProjectGuest(email !== hxr.data.project.createdBy);
      setNiraid(hxr.data.project.niraid);
      setStatus(hxr.data.project.status); 
      

      if (hxr.data?.success){
        const threeData = {
            id: orthoId,
          };
          
          const threeConfig = {
            headers: {
              "Content-Type": "x-www-form-urlencoded",
            },
          };
          const treeUrl = `${API_URL}/projects/deliverableAsset/getDeliverableAssetsById`;
    
          let treeHxr = await axios(treeUrl, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            data: threeData,
          });
          console.log('treeHxr',treeHxr)
          if (treeHxr.data?.success){
            // console.log('here', treeHxr.data)
            setOrtho(treeHxr.data.deliverableAsset);
          }
      }

         
      
    }
    getProjectById();
  }, []);

  const handleClose = () => {
    setShow(false);
    setShowEmailError(false);
  };

  

  const [parentFolder, setParentFolder] = useState("");

  const [treeData, setTreeData] = useState({
    id: "root",
    name: "",
    children: [],
  });

  const [showIcons, setShowIcons] = useState(false);

  

  const [user, setUser] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });

  const handleUpdateTree = async (tree) => {
    const data = {
      projectId: id,
      tree: tree,
      userId: user,
    };
    //   console.log('data',data)
    const config = {
      method: "post",
      url: `${API_URL}/projects/deliverableAsset/saveProjectTree`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    const xhr = await axios(config).catch((error) => {
      console.log("handleUpdateTree error", error);
      throw error;
    });

    console.log("xhr successful", xhr);
  };

 
  const [isDisabled, setIsDisabled] = useState(true);

  const RenderCracks = (props) => {
    // console.log('render cracks')
    return (
            <>
                <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>Crack Number</th>
                        <th>Crack Width (mm)</th>
                        <th>Length (mm)</th>
                        <th>Classification</th>
                        <th>BoundingBox_1</th>
                        <th>BoundingBox_2</th>
                        <th>BoundingBox_3</th>
                        <th>BoundingBox_4</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data?.map((crack) => (
                    <tr>
                        <th>{crack['Crack Number']}</th>
                        <th>{crack['Crack Width (mm)']}</th>
                        <th>{crack['Length (mm)']}</th>
                        <th>{crack['Classification']}</th>
                        <th>{crack['BoundingBox_1']}</th>
                        <th>{crack['BoundingBox_2']}</th>
                        <th>{crack['BoundingBox_3']}</th>
                        <th>{crack['BoundingBox_4']}</th>
                    </tr> 
                    ))} 
                                        
                </tbody>
                </Table>
                <br />
            <hr />
            <br />
            </>
  )}
  const RenderEfflorescence = (props) => {
    return (
        <>
            <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>Damage Number</th>
                    <th>Area(sq-mm)</th>
                    <th>BoundingBox_1</th>
                    <th>BoundingBox_2</th>
                    <th>BoundingBox_3</th>
                    <th>BoundingBox_4</th>
                </tr>
            </thead>
            <tbody>
                {props.data?.map((crack) => (
                <tr>
                    <th>{crack['Damage Number']}</th>
                    <th>{crack['Area(sq-mm)']}</th>
                    <th>{crack['BoundingBox_1']}</th>
                    <th>{crack['BoundingBox_2']}</th>
                    <th>{crack['BoundingBox_3']}</th>
                    <th>{crack['BoundingBox_4']}</th>
                </tr> 
                ))}                                     
            </tbody>
            </Table>
            <br />
            <hr />
            <br />
        </>
)}
  const RenderSpalling = (props) => {
    // console.log('spalling')
    return (
        <>
            <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>Damage Number</th>
                    <th>Area(sq-mm)</th>
                    <th>BoundingBox_1</th>
                    <th>BoundingBox_2</th>
                    <th>BoundingBox_3</th>
                    <th>BoundingBox_4</th>
                </tr>
            </thead>
            <tbody>
                {props.data?.map((crack) => (
                <tr>
                    <th>{crack['Damage Number']}</th>
                    <th>{crack['Area(sq-mm)']}</th>
                    <th>{crack['BoundingBox_1']}</th>
                    <th>{crack['BoundingBox_2']}</th>
                    <th>{crack['BoundingBox_3']}</th>
                    <th>{crack['BoundingBox_4']}</th>
                </tr> 
                ))}                                     
            </tbody>
            </Table>
            <br />
            <hr />
            <br />
        </>
)}

  const RenderDamageList = (props) => {
    // console.log('props.type', props.type)
    let rst = '';
    switch(props.type){
        case 'cracks':
            rst = <RenderCracks data={props.data} />
        break;
        case 'efflorescence':
            rst = <RenderEfflorescence data={props.data} />
        break;
        case 'spalling':
            rst = <RenderSpalling data={props.data} />
        break; 
        default: 
            rst = '' ;
        break; 
    }
    // console.log('rst', rst)
    return rst;
  }


  const handleDeleteOrthoDamage = async () => {
    console.log('id', id)
    Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#fdc20f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then(async (result) => {
        if (result.isConfirmed) {
    
            const data_obj = {
                id: orthoId
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/deliverableAsset/deleteDeliverableDamageById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            window.location.href = `/project/${id}/configDeliverables/${orthoId}/damages`
        }
      })
  }
  
  const setEnableMode = async(val) => {

    setEditMode(val);
    const data = {
      id: orthoId,
      edit: val,
    };

      console.log('data',data)
    const config = {
      method: "post",
      url: `${API_URL}/projects/deliverableAsset/saveEditMode`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    const xhr = await axios(config).catch((error) => {
      console.log("handleUpdateTree error", error);
      throw error;
    });

    console.log("xhr successful", xhr);
  }
  
  return (
    <>     
      <Header />
      <Container>
        <Row>
          <Col md="12">
            <form className="action-link-auth">
              <div className="project-dashboard">
                <div className="profile-actions">
                  <div className="project-actions-left left padding10px">
                    <Link className="no-link" to="/dashboard">
                      All Projects
                    </Link>{" "}
                    <span>
                      {" "}
                      <img src={chevronImg} width="15px" />{" "}
                    </span>{" "}
                    {project.name}
                  </div>
                  <div className="project-actions-right right">
                    <TopButtons id={id} />
                  </div>
                </div>
                <br />
                <hr />

                <div className="project-left edit-assets w100">
                  <ProjectMenu active="config" id={id} />
                  <div className="addIcon">
                  <div className="profile-actions">
                        <div className="project-actions-left left padding10px"> 
                            <input className="input-name" type="text" disabled={isDisabled} value={ortho.name} />
                            {/* <Button variant="dark"><ModeEditIcon /></Button> */}
                        </div>
                        <div className="project-actions-right right">
                            <Button variant="btn primary-btn-active next-btn-large" onClick={()=>handleDeleteOrthoDamage()}>
                                Re upload the Damages <BuildIcon />
                            </Button>
                            <br />
                            <br />
                            <div>
                            <label htmlFor="nira" className="label-custom-form margin-top-35" >  Enable Edit Mode </label>
                              <Form.Select
                                size="lg"
                                className="custom-select select-type"
                                name="assetTypeLabeled2"
                                value={editMode}
                                onChange={(e) => setEnableMode(e.target.value)}
                              >
                                <option key="true" value="true">
                                  Yes
                                </option>
                                <option key="false" value="false">
                                  No
                                </option>
                              </Form.Select>
                            </div>
                        </div>
                    </div>
                    <br />
                  </div>
                  <div className="listOrtho">
                    {ortho.damages?.map(damage=>(
                        <>
                            <h3>{damage.type}</h3>
                            <RenderDamageList type={damage.type} data={damage.data} />
                        </>
                    ))}
                  
                  </div>
                  <div className="editWrapper">
                    <div className="assetsContainer project-left w100"></div>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ConfigDeliverables;
