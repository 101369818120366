
import { STATUS } from '../constants';

export const FilterStatus = (statusList, status) => {
    return statusList.filter(function (el) {
        return el.type.includes(status); ;
      });
}

export const FlatArrayByStatusKey = (statusList, status) => {
    const newList = [];
    statusList.array.forEach(element => {
      newList.push(element['value']);
    });
    return newList;
}

export const MatchStatus = (currentStatus) => {

  const matches = [];
  const statusList = STATUS.filter(function (el) {
    // console.log('el',el)
    currentStatus.map((st) => {
      // console.log('st',st)
      if(el.value === st){
        matches.push(el);
      }
    });
      
  });
  return matches;
}

export const GetStatusName = (currentStatus) => {
  let status = '-'
  STATUS.map(function (el) {
      console.log('el.value',el.value)
      console.log('currentStatus',currentStatus)
      if(el.value === currentStatus){
        status = el.label;
      }
      
  });
  return status;
}


export const FlatArray = (currentStatus) => {
  const newList = [];
  currentStatus.forEach(element => {
    newList.push(element['value']);
  });
  return newList;
}

export const GetCurrentStatus = (statusList, currentStatus) => {
  const idx = statusList.findIndex((el) => el === currentStatus);
  return statusList.splice(idx, statusList.length);
}