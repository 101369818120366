import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context'
import { Link, useParams } from "react-router-dom";
import ProjectMenu from '../components/projectMenu';

const Project = (props) => {

    let { id } = useParams();    

    const [project, setProject] = useState([]);

    useEffect(() => {
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setProject(hxr.data.project)

            let niraProto = window.location.protocol;
    if (niraProto == "file:") {
        niraProto = "http:";
        if (document.currentScript && document.currentScript.src) {
            const scriptSrc = document.currentScript.src.toLowerCase();
            if (scriptSrc.startsWith("http:")) {
                niraProto = "http:";
            }
            else if (scriptSrc.startsWith("https:")) {
                niraProto = "https:";
            }
        }
    }
    function niraEmbedInit(embedElements) {
        const niraElems = embedElements ?
            (typeof embedElements[Symbol.iterator] === 'function' ? embedElements : [embedElements])
            :
                (document.querySelectorAll("[data-nira-asset-id]"));
        for (let elem of niraElems) {
            const assetId = elem.dataset.niraAssetId || elem.dataset.assetId;
            const niraUid = elem.dataset.niraUid;
            const niraToken = elem.dataset.niraToken;
            if (assetId === undefined) {
                continue;
            }
            const iframe = elem.tagName === "IFRAME" ? elem : document.createElement("iframe");
            if (elem.dataset.niraAutofocus) {
                iframe.addEventListener("load", () => {
                    iframe.contentWindow.focus();
                });
            }
            document.addEventListener("visibilitychange", () => {
                iframe.contentWindow.postMessage({ type: "visibilitychange" }, "*");
            }, false);
            document.addEventListener("pageshow", () => {
                iframe.contentWindow.postMessage({ type: "pageshow" }, "*");
            }, false);
            document.addEventListener("pagehide", () => {
                iframe.contentWindow.postMessage({ type: "pagehide" }, "*");
            }, false);
            iframe.addEventListener("load", () => {
                var _a, _b;
                const msg = {
                    type: "iframe_initialize",
                    useTransparentBackground: !!((_a = elem === null || elem === void 0 ? void 0 : elem.dataset) === null || _a === void 0 ? void 0 : _a.useTransparentBackground),
                    forceWebGlTransparency: !!((_b = elem === null || elem === void 0 ? void 0 : elem.dataset) === null || _b === void 0 ? void 0 : _b.forceWebGlTransparency),
                    referrer: document.referrer,
                    parenthost: window.location.origin,
                };
                iframe.contentWindow.postMessage(msg, "*");
            });
            iframe.setAttribute('allowfullscreen', true);
            iframe.setAttribute('allowtransparency', true);
            iframe.setAttribute('frameBorder', 0);
            iframe.setAttribute('name', `nira-asset-${assetId}`);
            iframe.setAttribute('src', `${niraProto}//aren.nira.app${niraUid ? `/p/${niraUid}/${niraToken}` : ''}/a/${assetId}`);
            if (elem !== iframe) {
                iframe.style.width = '100%';
                iframe.style.height = '100%';
                iframe.style.border = 'none';
                elem.appendChild(iframe);
            }
        }
        
    }

            document.addEventListener("DOMContentLoaded", () => {
                niraEmbedInit(document.getElementById("niraEmbedAsset1"));
            });

        }

        getProjectById();

    }, [])

    

   

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form>
                        <div className="fullscreen">
                            <iframe data-nira-asset-id={project.niraid} className="niraEmbedAsset1" id="niraEmbedAsset1" ></iframe>
                        </div>
                        <ProjectMenu active='3dmodel' id={id} />
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Project;