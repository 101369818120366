import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import Dropzone from '../components/dropzone';



const ProjectAssets = (props) => {
    
    let { id } = useParams();

    const [nextDisable, setNextDisable] = useState(true);
    
    const [materialType, setMaterialType] = useState(false);
    const [damageTypeCracks, setDamageTypeCracks] = useState(false);
    const [damageTypeSpalling, setDamageTypeSpalling] = useState(false);
    const [damageTypeEflorecence, setDamageTypeEflorencece] = useState(false);
    const [damageTypeHoneycomb, setDamageTypeHoneycomb] = useState(false);
    const [damageTypeExposedRebar, setDamageTypeExposedRebar] = useState(false);
    const [damageTypeCorrossion, setDamageTypeCorrossion] = useState(false);
    const [damageTypeRustStain, setDamageTypeRustStain] = useState(false);
    const [damageTypeExposedPrestressing, setDamageTypeExposedPrestressing] = useState(false);

    //CRACKS
    const [cracksGood, setCracksGood] = useState("");
    const [cracksFair, setCracksFair] = useState("");
    const [cracksPoor, setCracksPoor] = useState("");
    const [cracksCritical, setCracksCritical] = useState("");

    //CRACKS
    const [spallingGood, setSpallingGood] = useState("");
    const [spallingFair, setSpallingFair] = useState("");
    const [spallingPoor, setSpallingPoor] = useState("");
    const [spallingCritical, setSpallingCritical] = useState("");

    //Efflorencece
    const [efflorecenceGood, setEfflorecenceGood] = useState("");
    const [efflorecenceFair, setEfflorecenceFair] = useState("");
    const [efflorecencePoor, setEfflorecencePoor] = useState("");
    const [efflorecenceCritical, setEfflorecenceCritical] = useState("");

    //Honeycomb
    const [honeycombGood, setHoneycombGood] = useState("");
    const [honeycombFair, setHoneycombFair] = useState("");
    const [honeycombPoor, setHoneycombPoor] = useState("");
    const [honeycombCritical, setHoneycombCritical] = useState("");

    //Exposed Rebar
    const [exposedRebarGood, setExposedRebarGood] = useState("");
    const [exposedRebarFair, setExposedRebarFair] = useState("");
    const [exposedRebarPoor, setExposedRebarPoor] = useState("");
    const [exposedRebarCritical, setExposedRebarCritical] = useState("");

    //Corrosion
    const [corrosionGood, setCorrosionGood] = useState("");
    const [corrosionFair, setCorrosionFair] = useState("");
    const [corrosionPoor, setCorrosionPoor] = useState("");
    const [corrosionCritical, setCorrosionCritical] = useState("");

    //Rust Stain
    const [rustStainGood, setRustStainGood] = useState("");
    const [rustStainFair, setRustStainFair] = useState("");
    const [rustStainPoor, setRustStainPoor] = useState("");
    const [rustStainCritical, setRustStainCritical] = useState("");

    //Exposed Prestressing
    const [exposedPrestressingGood, setExposedPrestressingGood] = useState("");
    const [exposedPrestressingFair, setExposedPrestressingFair] = useState("");
    const [exposedPrestressingPoor, setExposedPrestressingPoor] = useState("");
    const [exposedPrestressingCritical, setExposedPrestressingCritical] = useState("");
    
    
      const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
      });


    const validationSchema = {
        materialType: 'You should select a material type',
        damageType: 'You should select at least one Damage Type',
        severityClassification: 'You should fullfil the severy classification criteria'
    }; 
    const errorMsg = {
        materialType: 'You should select a material type',
        damageType: 'You should select at least one Damage Type',
        severityClassification: 'You should fullfil the severy classification criteria'
    }; 

    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedDamages, setSelectedDamages] = useState('');
    const [metricProperty, setMetricProperty] = useState('');
    
    const damageMetric = {
        "Cracks": "unit",
        "Spalling": "unit",
        "Efflorecence": "area",
        "Honeycomb": "area",
        "Exposed Rebar": "area",
        "Corrosion": "area",
        "Rust Stain": "area",
        "Exposed Prestressing": "area",
    }

    const [projectHasParams, setProjectHasParams] = useState(false);

    useEffect(() => {
        // setProjectId(id);
        async function getProjectParamsByProjectId() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectParamsByProjectId`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            if(hxr.data.success === true && hxr.data.projects.length > 0) {
                setProjectHasParams(true);
                // setMaterialType(hxr.data.projects[0].materialType);
                setSelectedMaterial(materialTypes[hxr.data.projects[0].materialType]);
                const damages = JSON.parse(hxr.data.projects[0].damages);
                console.log('damages',damages)
               
                
                damages.map(el=>{
                    let damageMetricType = damageMetric[el.damageType];
                    console.log('defaultUnits',defaultUnits)
                    // console.log('damageMetricType',damageMetricType)
                    el.damageMetricType = damageMetricType;
                    if(damageMetricType === "unit"){
                        el.unit = defaultUnits;
                        
                    }else{
                        el.unit = defaultAreaUnits;
                    }
                })
                setSelectedDamages(damages)
                console.log('selectedDamages',selectedDamages)
                // console.log(damages)
                // damages.map(el=>console.log('severityClassification',el))
               
            }else{
                setProjectHasParams(false);
            }
            // setAssets(hxr.data.assets)            
        }

        getProjectParamsByProjectId();

    }, [])
    
    const parseMetricSystem = (unit, metricSystem) => {
        
        if(metricSystem === "1"){ 
            if(unit === "unit"){
                return 'in'
            }else{
                return 'sq-in';
            }
        }else{
            if(unit === "unit"){
                return 'mm'
            }else{
                return 'sq-mm';
            }
        }
    }
    
    // const [email, setEmail] = useState(() => {
    //     const saved = localStorage.getItem('currentUser');
    //     const initialValue = JSON.parse(saved);
    //     return initialValue.user.email || "";
    //   });
    
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const [materialTypeError, setMaterialTypeError] = useState(null)
    const [damageTypeError, setDamageTypeError] = useState(null)

    const handleSelectChange = (event) => {
        console.log(event);
        console.log('event.target.value',event.target.value);
        if(event.target.value === 0){
            setMaterialTypeError(true)
        }else{
            setMaterialTypeError(false)
        }
        setMaterialType(event.target.value)
    };

    const validationErrors = {
        materialtype: null,
        damageType: null
    }

     async function onSubmit(data) {
        console.log('data',data)
        console.log('materialType', materialType)
        if(materialType==null || materialType == 0){
            setMaterialTypeError(true)
            validationErrors.materialType = true;
        }else{
            validationErrors.materialType = false;
        }

        console.log('damageTypeCracks',damageTypeCracks)
        //damageTypeCracks damageTypeSpalling damageTypeEflorecence damageTypeHoneycomb damageTypeExposedRebar damageTypeCorrossion damageTypeRustStain damageTypeExposedPrestressing
        if(damageTypeCracks  || damageTypeSpalling  || damageTypeEflorecence  || damageTypeHoneycomb  ||
           damageTypeExposedRebar  || damageTypeCorrossion  || damageTypeRustStain  || damageTypeExposedPrestressing   ){
            validationErrors.damageType = false;
            setDamageTypeError(false)
        }else{
            validationErrors.damageType = true;
            setDamageTypeError(true)
        }

        console.log('validationErrors',validationErrors);

        if(validationErrors.materialType || validationErrors.damageType){
            //continue
        }else{
            Swal.fire({
                title: 'Successful!',
                text: 'Thank you for creating new project on Aren. Our team will start processing the data and get back to you as soon as possible.',
                confirmButtonColor: '#FDC20F',
                confirmButtonText: 'See project',
                confirmButtonTextColor: '#000',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                    props.history.push(`/dashboard`) 
                }
              })
        }
    
            
        
        

    }
    const dispatch = useAuthDispatch()

    const handleNext = () => {
       console.log('next')
    }

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
      { name: 'Imperial', value: '1' },
      { name: 'Metric', value: '2' },
    ];

    const [metric, setMetric] = useState('1')

    

    

    const handleChange = (label) => { 
        let enable = false;
        switch(label){
            case "Cracks":
                enable = damageTypeCracks === true ? false : true; 
                setDamageTypeCracks(enable)
                break;
            case "Spalling":
                enable = damageTypeSpalling === true ? false : true; 
                setDamageTypeSpalling(enable)
                break;
            case "Efflorescence":
                enable = damageTypeEflorecence === true ? false : true; 
                setDamageTypeEflorencece(enable)
                break;
            case "Honeycomb":
                enable = damageTypeHoneycomb === true ? false : true; 
                setDamageTypeHoneycomb(enable)
                break;
            case "Exposed rebar":
                enable = damageTypeExposedRebar === true ? false : true; 
                setDamageTypeExposedRebar(enable)
                break;
            case "Corrosion":
                enable = damageTypeCorrossion === true ? false : true; 
                setDamageTypeCorrossion(enable)
                break;
            case "Rust Stain":
                enable = damageTypeRustStain === true ? false : true; 
                setDamageTypeRustStain(enable)
                break;
            case "Exposed Prestressing":
                enable = damageTypeExposedPrestressing === true ? false : true; 
                setDamageTypeExposedPrestressing(enable)
                break;                
        }
        setDamageTypeError(false) 
        
      };

    const [defaultUnits, setDefaultUnits]  = useState('in')

    const [defaultAreaUnits, setDefaulAreatUnits]  = useState('sq-in')

  

    const materialTypes = ['Material Type','Concrete','Steel','Mansonry','Limestone']

    const radioList = ['Cracks','Spalling','Efflorescence','Honeycomb','Exposed rebar', 'Corrosion','Rust Stain', 'Exposed Prestressing']

    const handleClick = () => {
        window.location = `/project/${id}/edit`
    }
    // console.log('selectedDamages', selectedDamages)
    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard parameters">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    Set Parameters
                                 </div>
                                <div className="project-actions-right right">
                                    <Button  variant="btn primary-btn-active next-btn"  onClick={handleClick}>
                                        Back
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                            { projectHasParams ? (
                                <>
                                    <div className="project-left cracks maxHeight450">
                                        <div className="form-group">
                                            <label htmlFor="name" className="label-parameters margin-bottom-15">Material Type</label>                                        
                                            <Form.Select size="lg" 
                                                className="custom-select" 
                                                name="assetType" 
                                                onChange={handleSelectChange}
                                                disabled={true}
                                            >
                                                <option value="0">{selectedMaterial}</option>
                                            </Form.Select>
                                        </div>

                                        <hr />
                                        <br />
                                        <div className="damage-type margin-bottom-50">
                                            <span className="damage-title">Damage Type</span>
                                        </div>
                                        {damageTypeError===true ? (
                                                <div className="invalid-feedback always-display">{errorMsg.damageType}</div>
                                            ) : ("") }                                    
                                        {selectedDamages && 
                                        selectedDamages.length > 0 ?
                                        selectedDamages.map((type) => (
                                                <>
                                                {/* {console.log('type', type)} */}
                                                {type.isSelected === true ? (
                                                    <div className="form-group margin-bottom-15">                                      
                                                        <Form.Check 
                                                            type='checkbox'
                                                            // id={`default-${type}`}
                                                            label={`${type.damageType}` }
                                                            checked={true}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                ) : ''}
                                            
                                            </>
                                            )):''
                                        }                             
                                </div>
                                <div className="project-right cracks maxHeight450 right padding-left-15">  
                                <div className="damage-type margin-bottom-15">
                                    <span className="damage-title">Severity Classification Criteria</span>
                                </div>
                                {selectedDamages && 
                                        selectedDamages.length > 0 ?
                                        selectedDamages.map((type) => (
                                                <>
                                                {/* {console.log('type', type)} */}
                                                {type.isSelected === true ? (
                                                    <div className="feature-setting ">
                                                    <div className="feature-setting-title">
                                                    {type.damageType}
                                                        <hr />
                                                    </div>
                                                    <div className="feature-setting-body">
                                                        <div className="feature-setting-body-row">
                                                            <div className="feature-setting-body-row-left">
                                                                <span className="label">Good </span>
                                                                <span className="input-parameters">
                                                                    <input type="text" defaultValue={'< '+type.severityClassification.good +' '+ parseMetricSystem(type.damageMetricType,type.unit)} disabled />
                                                                </span>
                                                            </div>
                                                            <div className="feature-setting-body-row-right">
                                                            <span className="label">Fair</span>
                                                                <span className="input-parameters">
                                                                <input type="text" defaultValue={'< '+type.severityClassification?.fair+' '+ parseMetricSystem(type.damageMetricType,type.unit)} disabled />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="feature-setting-body-row">
                                                            <div className="feature-setting-body-row-left">
                                                                <span className="label">Poor</span>
                                                                <span className="input-parameters">
                                                                <input type="text" defaultValue={'< '+type.severityClassification?.poor+' '+ parseMetricSystem(type.damageMetricType,type.unit)} disabled />
                                                                </span>
                                                            </div>
                                                            <div className="feature-setting-body-row-right">
                                                            <span className="label">Critical</span>
                                                                <span className="input-parameters">
                                                                <input type="text" defaultValue={'> '+type.severityClassification?.critical+' '+ parseMetricSystem(type.damageMetricType,type.unit)} disabled />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : ''}
                                            
                                            </>
                                            )):''
                                        } 
                                {damageTypeCracks ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Cracks - Width
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setCracksGood(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCracksFair(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCracksPoor(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCracksCritical(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") }
                                {damageTypeSpalling ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Spalling - Diameter
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setSpallingGood(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setSpallingFair(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setSpallingPoor(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setSpallingCritical(e.target.value)} defaultValue={defaultUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") } 
                                {damageTypeEflorecence ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Efflorescence - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setEfflorecenceGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setEfflorecenceFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setEfflorecencePoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setEfflorecenceCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") }
                                {damageTypeHoneycomb ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Honeycomb - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setHoneycombGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setHoneycombFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setHoneycombPoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setHoneycombCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") }   
                                {damageTypeExposedRebar ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Exposed Rebar - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setExposedRebarGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedRebarFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedRebarPoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedRebarCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") } 
                                {damageTypeCorrossion ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Corrosion - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setCorrosionGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCorrosionFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCorrosionPoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setCorrosionCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") }
                                {damageTypeRustStain ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Rust Stain - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setRustStainGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setRustStainFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setRustStainPoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setRustStainCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ("") } 
                                {damageTypeExposedPrestressing ? (
                                    <div className="feature-setting ">
                                        <div className="feature-setting-title">
                                            Exposed Prestressing - Area
                                            <hr />
                                        </div>
                                        <div className="feature-setting-body">
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Good</span>
                                                    <span className="input-parameters">
                                                        <input type="text" onChange={e=>setExposedPrestressingGood(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Fair</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedPrestressingFair(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="feature-setting-body-row">
                                                <div className="feature-setting-body-row-left">
                                                    <span className="label">Poor</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedPrestressingPoor(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                                <div className="feature-setting-body-row-right">
                                                <span className="label">Critical</span>
                                                    <span className="input-parameters">
                                                    <input type="text" onChange={e=>setExposedPrestressingCritical(e.target.value)} defaultValue={defaultAreaUnits} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ("") } 
                                </div>
                                </>
                            ) : (
                                <>
                                    No parameters set
                                </>
                            ) }        

                            
                            <hr />
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step project-step-active">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text project-step-on">Set Parameters</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ProjectAssets;