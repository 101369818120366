import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context'


const Project = (props) => {

    
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Project Name is required')   
            .min(3, 'Project Name must be at least 3 characters')
            .max(50, 'Project Name must be less than 50 characters'),
        country: Yup.string()
            .required('Country is required'),
        state: Yup.string()
            .required('State is required'),
        city: Yup.string()
            .required('City is required'),  
        address: Yup.string()
            .required('Address is required')           
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        console.log('initialValue', initialValue);
        console.log('onSubmit', data);
        // console.log('user',user);
        const data_obj = {
            name: data.name,
            address: data.address,
            country: data.country,
            city:  data.city,
            state:  data.state,
            assetsType:  data.assetType,
            createdBy: initialValue.user.email
        }
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/projects`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        const _data = await hxr.data;
        console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            props.history.push(`/project/${_data.project._id}/assets`);
            
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
    const dispatch = useAuthDispatch()

    const handleLogout = () => {
        logout(dispatch);
       window.location.href = '/login';
    }

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
      { name: 'Imperial', value: '1' },
      { name: 'Metric', value: '2' },
    ];

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form onSubmit={handleSubmit(onSubmit)} className="action-link-auth">
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                    Add Project details
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" type="submit">
                                        Next
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className="project-left">
                                
                                    <div className="form-group">
                                        <label htmlFor="name" className="label-custom">Project Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            // defaultValue={name}
                                            // onChange={(e) => setName(e.target.value)}
                                            autoComplete="off"
                                            className={`input ${errors.name ? 'is-invalid' : ''}`}
                                            // placeholder="Your Password"
                                            {...register('name')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>
                                    <div className="form-group">
                                        {/* <label htmlFor="name" className="label-custom">Asset type</label> */}
                                        <Form.Select size="lg" className="custom-select" name="assetType" {...register('assetType')}>
                                            <option>Asset Type</option>
                                            <option value="1">Bridge</option>
                                            <option value="2">Building Facade</option>
                                            <option value="3">Cooling Tower</option>
                                            <option value="4">Industrial Chimney</option>
                                            <option value="5">Dam</option>
                                            <option value="6">Levee</option>
                                            <option value="7">Culvert</option>
                                            <option value="8">Tunnel</option>
                                        </Form.Select>
                                    </div>

                                    <hr />
                                    <br />
                                    <div className="form-group">
                                        <label htmlFor="address" className="label-custom">Address</label>
                                        <input
                                            type="text"
                                            name="address"
                                            id="address"
                                            autoComplete="off"
                                            className={`input ${errors.address ? 'is-invalid' : ''}`}
                                            {...register('address')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.address?.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="city" className="label-custom">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            autoComplete="off"
                                            className={`input ${errors.city ? 'is-invalid' : ''}`}
                                            // placeholder="Your Password"
                                            {...register('city')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.city?.message}</div>
                                    </div>
                                    

                                    <div className="form-group">
                                        <label htmlFor="state" className="label-custom">State</label>
                                        <input
                                            type="text"
                                            name="state"
                                            id="state"
                                            autoComplete="off"
                                            className={`input ${errors.state ? 'is-invalid' : ''}`}
                                            {...register('state')}
                                            // placeholder="Your Password"
                                            {...register('state')}
                                        // required value={password} onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="invalid-feedback">{errors.state?.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="country" className="label-custom">Country</label>
                                        <input
                                            type="text"
                                            name="country"
                                            id="country"
                                            autoComplete="off"
                                            className={`input ${errors.country ? 'is-invalid' : ''}`}
                                            {...register('country')}
                                        />
                                        <div className="invalid-feedback">{errors.country?.message}</div>
                                    </div>
                            </div>
                            <div className="project-right">                                
                            </div>
                            <hr />
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div>
                        </div>
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Project;