import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Form,
  Modal,
  Table
} from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../components/header";
import addInvite from "../images/add-invite.svg";
import addIconButton from "../images/addIconButton.svg";
import profilePlaceholder from "../images/user-profile-tools.svg";
import chevronImg from "../images/chevron.svg";
import deleteInviteImg from "../images/deleteInvite.svg";
import assetPlaceholder from "../images/projectDetails.svg";
import deleteImg from "../images/delete.svg";
import { API_URL, ASSET_DIR, RESULT_DIR } from "../constants";
import { useAuthState, useAuthDispatch, logout } from "../context";
import ProjectMenu from "../components/projectMenu";
import Moment from "moment";
import Thumbnail from "../components/thumbnails";
import FolderName from "../components/renderfolder";
import downloadIcon from "../images/downloadarrow.svg";
import { saveAs } from "../library/saveascustom";
import EditDetails from "../components/EditDetails";
import TopButtons from "../components/topButtons";
import { toLength } from "lodash";
import Dropzone from "../components/dropzone";

import ReactDOM from "react-dom";
import FolderTree from "react-folder-tree";
import useTreeState, { testData } from "use-tree-state";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';



const { uuid } = require("uuidv4");

const ConfigDeliverables = (props) => {
  let { id } = useParams();
  const [projectId, setProjectId] = useState("");
  const [projectInvites, setProjectInvites] = useState([]);
  const [isProjectGuest, setIsProjectGuest] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [invite, setInvite] = useState("");
  const handleShow = () => setShow(true);
  const [project, setProject] = useState([]);
  const [assets, setAssets] = useState([]);
  const [niraid, setNiraid] = useState([]);
  const [status, setStatus] = useState([]);

  const [folderName, setFolderName] = useState("");
  const [folderLevel, setFolderLevel] = useState(0);
  const [folderType, setFolderType] = useState("");
  const [folderId, setFolderId] = useState(""); // parent folderId
  const [parentFolderName, setParentFolderName] = useState("");
  const [hasAssetId, setHasAssetId] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [assetName, setAssetName] = useState("");
  const [isOrthoUpload, setIsOrthoUpload] = useState("");
  const [isCsvProcess, setIsCsvProcess] = useState("");

  const [expanded, setExpanded] = useState([]);
  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const [showEditFolder, setShowEditFolder] = useState(false);
  const [folderEditName, setFolderEditName] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [defaultFolderName, setDefaultFolderName] = useState("");


  const handleLogout = () => {
    window.location.href = "/login";
  };

  const handleEdit = () => {
    window.location.href = `/project/${id}/edit/details`;
  };
  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });
  useEffect(() => {
    setProjectId(id);
    async function getProjectById() {
      const data_obj = {
        projectid: id,
      };
      console.log("data_obj", data_obj);
      const config = {
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
      };
      const url = `${API_URL}/projects/getProjectById`;

      let hxr = await axios(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: data_obj,
      });
      setProject(hxr.data.project);
      setIsProjectGuest(email !== hxr.data.project.createdBy);
      setNiraid(hxr.data.project.niraid);
      setStatus(hxr.data.project.status); 
      setTreeData({
        id: "root",
        name: "Project: " + hxr.data.project.name,
        level: 0,
        children: [
          {
            id: "deliverables",
            name: "Deliverables",
            children: [],
            level: 1,
          },
        ],
      });  

      if (hxr.data?.success){
        const threeData = {
            projectId: id,
          };
          
          const threeConfig = {
            headers: {
              "Content-Type": "x-www-form-urlencoded",
            },
          };
          const treeUrl = `${API_URL}/projects/deliverableAsset/getTreeByProjectId`;
    
          let treeHxr = await axios(treeUrl, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            data: threeData,
          });
          console.log('treeHxr',treeHxr)
          if (treeHxr.data?.success && treeHxr.data?.tree[0]?.tree){
            console.log('here', treeHxr.data.tree[0].tree)
            setTreeData(treeHxr.data.tree[0].tree);
          }
      }

         
      
    }
    getProjectById();
  }, []);

  const handleClose = () => {
    setShow(false);
    setShowEmailError(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setIsInitialStep(true);
    setIsOrthoUpload(false);
    setIsCsvProcess(false);
  };
  const handleEditCloseModal = () => {
    showEditModal(false);
    setIsInitialStep(true);
    setIsOrthoUpload(false);
    setIsCsvProcess(false);
  };

  const handleCreateFolder = (isConfig) => {
    setShowModal(true);
    setIsFolder(!isConfig);
    setAssetName("");
  };

  const [isFolder, setIsFolder] = useState(false);

  const folder = "null";
  const [isInitialStep, setIsInitialStep] = useState(true);

  const processModal = (isFolder) => {
    if (isFolder) {
      setShowModal(false);
    } else {
      setIsInitialStep(false);
    }
  };

  const handleOver = () => {
    console.log("over");
    setShowIcons(true);
  };

  const handleOut = () => {
    console.log("out");
    setShowIcons(false);
  };

  const hadnleSetParentFolder = (id) => {
    setParentFolder(id);
    console.log("parent folder", id);
  };

  const [parentFolder, setParentFolder] = useState("");

  const [treeData, setTreeData] = useState({
    id: "root",
    name: "",
    children: [],
  });

  const [showIcons, setShowIcons] = useState(false);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      level={nodes.level}
      onClick={() => {
        setFolderLevel(nodes.level);
        setFolderId(nodes.id);
        setParentFolderName(nodes.name);
        console.log("nodes.id", nodes.id);
        if (nodes.id == "root" || nodes.id == "deliverables") {
          setShowDeleteFolder(false);
          setShowEditFolder(false);
        } else {
          setShowDeleteFolder(true);
          setShowEditFolder(true);
        }
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  function findDeep(array, id, node) {
    var object;

    array.some(function f(a) {
      if (a.id === id) {
        object = a;
        a.children.push(node);
        // setExpanded([id])
        return true;
      }
      if (Array.isArray(a.children)) {
        return a.children.some(f);
      }
    });
    return object;
  }
  const removeKey = (data, id) => {
    // look to see if object exists
    const index = data.findIndex((x) => x.id === id);
    if (index > -1) {
      data.splice(index, 1); // remove the object
      return true;
    } else {
      // loop over the indexes of the array until we find one with the id
      return data.some((x) => {
        if (x.children) {
          return removeKey(x.children, id);
        } else {
          return false;
        }
      });
    }
  };

  const insertInLevel = (level, node) => {
    console.log("folderLevel", folderLevel);
    const _node = {
      id: uuid(),
      name: node.text,
      children: [],
      level: 1,
      type: node.type ?? "folder",
    };
    console.log("node", _node);
    const id = folderId;
    console.log("folder id", id);
    const tree = treeData;

    findDeep(tree.children, id, _node);
    setTreeData(tree);
    handleUpdateTree(tree);
    // setExpanded([id])
  };

//   const [editFolderName, settEditFolderName] = useState("");
  const getNodeData = (array, id) => {
    var object;
    array.some(function f(a) {
      if (a.id === id) {
        object = a;
        console.log("folder data", a);        
        return true;
      }
      if (Array.isArray(a.children)) {
        return a.children.some(f);
      }
    });
    return object;
  };
  const saveNodeData = (array, id) => {
    var object;
    array.some(function f(a) {
      if (a.id === id) {
        object = a;
        a.name = defaultFolderName;        
        return true;
      }
      if (Array.isArray(a.children)) {
        return a.children.some(f);
      }
    });
    return object;
  };

  const handleEditFolder = () => {
    const id = folderId;
    const tree = treeData;
    const node = getNodeData(tree.children, id);
    setShowEditModal(true);
    setDefaultFolderName(node.name);
  };

  const handleSaveEditFolder = () => {
    const id = folderId;
    const tree = treeData;
    const node = saveNodeData(tree.children, id);    
    setTreeData(tree);
    handleUpdateTree(tree);
    setShowEditModal(false);
  };

  const handleDeleteAsset = () => {
    console.log("delete");
    const id = folderId;
    const tree = treeData;
    removeKey(tree.children, id);
    setTreeData(tree);
    handleUpdateTree(tree);
  };

  const handleCreateNewFolder = () => {
    console.log("folder name", folderName);
    console.log("folder type", folderType);
    const node = {};
    node.text = folderName;
    node.type = folderType;
    insertInLevel(folderLevel, node);
  };

  const [user, setUser] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });

  const handleUpdateTree = async (tree) => {
    const data = {
      projectId: id,
      tree: tree,
      userId: user,
    };
    //   console.log('data',data)
    const config = {
      method: "post",
      url: `${API_URL}/projects/deliverableAsset/saveProjectTree`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    const xhr = await axios(config).catch((error) => {
      console.log("handleUpdateTree error", error);
      throw error;
    });

    console.log("xhr successful", xhr);
  };

  const handleCreateAssetDeliverable = async () => {
    try {
      if (!assetName || assetName == "") {
        alert("A name must be provided");
        return false;
      }

      const data = {
        projectId: id,
        name: assetName,
        user: user,
      };
      //   console.log('data',data)
      const config = {
        method: "post",
        url: `${API_URL}/projects/deliverableAsset/create`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      };

      const xhr = await axios(config).catch((error) => {
        console.log("error", error);
        throw error;
      });

      console.log("save project", xhr);

      if (xhr && xhr.data.success) {
        // window.location.reload();
        console.log(
          "xhr.data.deliverableAsset.projectId",
          xhr.data.deliverableAsset.projectId
        );
        window.location.href = `/project/${xhr.data.deliverableAsset.projectId}/configDeliverables/${xhr.data.deliverableAsset._id}/upload`
        
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleOrtho = () => {
    setIsInitialStep(false);
    setIsOrthoUpload(false);
    setIsCsvProcess(true);
  };
  const handleCloseEditModal = () =>{
    setShowEditModal(false);
  }

  

  
  
  return (
    <>
      
      <Header />
      <Container>
        <Row>
          <Col md="12">
            <form className="action-link-auth">
              <div className="project-dashboard">
                <div className="profile-actions">
                  <div className="project-actions-left left padding10px">
                    <Link className="no-link" to="/dashboard">
                      All Projects
                    </Link>{" "}
                    <span>
                      {" "}
                      <img src={chevronImg} width="15px" />{" "}
                    </span>{" "}
                    {project.name}
                  </div>
                  <div className="project-actions-right right">
                    <TopButtons id={id} />
                  </div>
                </div>
                <br />
                <hr />

                <div className="project-left edit-assets w100">
                  <ProjectMenu active="config" id={id} />
                  <div className="addIcon">
                  <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                Create an Orthomosaic
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" onClick={() => handleCreateAssetDeliverable(isFolder)}>
                                        Next
                                    </Button>
                                </div>
                            </div>
                            <br />
                            {/* <hr /> */}
                  </div>
                  <div className="listOrtho">
                    <div className="form-group"> 
                        <label>Name</label>
                        <br />
                        <br />
                        <input
                            type="text"
                            name="name"
                            id="name"
                            onChange={(e) => setAssetName(e.target.value)}
                            autoComplete="off"
                            className={`input w60 `}
                        />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="project-dashboard-footer">
                    <div className="project-footer-left project-footer-column">
                        <span className="project-step project-step-active">1</span>
                        <span className="project-step-text">Create an Orthomosaic</span>
                    </div>
                    <div className="project-footer-middle project-footer-column">
                        <span className="project-step">2</span>
                        <span className="project-step-text">Upload Master Image</span>
                    </div>
                    <div className="project-footer-right project-footer-column">
                        <span className="project-step">3</span>
                        <span className="project-step-text">Upload Damage Type</span>
                    </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ConfigDeliverables;
