import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import addInvite from '../images/add-invite.svg';
import addIconButton from '../images/addIconButton.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import chevronImg from '../images/chevron.svg';
import deleteInviteImg from '../images/deleteInvite.svg';
import assetPlaceholder from '../images/projectDetails.svg';
import deleteImg from '../images/delete.svg';
import { API_URL, ASSET_DIR, RESULT_DIR } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import ProjectMenu from '../components/projectMenu';
import Moment from 'moment';
import Thumbnail from '../components/thumbnails';
import downloadIcon from '../images/downloadarrow.svg';
import {saveAs} from 'file-saver'
import EditDetails from '../components/EditDetails';
import TopButtons from '../components/topButtons';

const Project = (props) => {

    let { id } = useParams(); 
    const [projectId, setProjectId] = useState("");
    const [projectInvites, setProjectInvites] = useState([]);
    const [isProjectGuest, setIsProjectGuest] = useState(false);
    const [show, setShow] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [invite, setInvite] = useState("");
    const handleShow = () => setShow(true);
    const [project, setProject] = useState([]);

    const handleLogout = () => {
        
       window.location.href = '/login';
    }

    

    const [assets, setAssets] = useState([]);
    const [niraid, setNiraid] = useState([]);
    const [labeledNiraId, setLabeledNiraid] = useState([]);
    const [status, setStatus] = useState([]);

    const [email, setEmail] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });

    useEffect(() => {
        setProjectId(id);
        async function getAssetsByProject() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getResultsByProject`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setAssets(hxr.data.assets)            
        }

        getAssetsByProject();

    }, [])
    

    useEffect(() => {
        setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setProject(hxr.data.project)
            console.log('email', email)
            console.log('hxr.data.project.createdBy', hxr.data.project.createdBy)
            
            setIsProjectGuest(email !== hxr.data.project.createdBy);
            setNiraid(hxr.data.project.niraid)
            setStatus(hxr.data.project.status)
            console.log('isProjectGuest',isProjectGuest)
        }
        getProjectById();
    }, [])

    const handleDeleteOne = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#fdc20f',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteResultsById`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                console.log('hxr',hxr.data)
                document.getElementById(`asset-${id}`).remove()
                // Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                //   )
            }
          })
    }

    const handleDeleteAll = async (id) => {
        console.log('id', id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You will delete all your project",
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#585858',
            confirmButtonText: 'Yes, Delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/projects/deleteProject`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                // console.log('hxr',hxr.data)
                // document.getElementById(`asset-${id}`).remove()
                Swal.fire({
                    title: 'Deleted!',
                    text: "Your project has been deleted.",
                    showCancelButton: false,
                    confirmButtonColor: '#fdc20f',
                    confirmButtonText: 'Go to the Dashboard!',
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/dashboard';
                    }
                })
            }
          })
    }


   

    const handleSubmitProjectDetails = async (e) => {        
        e.preventDefault();

        try{
            const data = {
                projectid: id,
                niraid: niraid,
                status: status
              }
              console.log('data',data)
            const config = {
                method: 'post',
                url: `${API_URL}/projects/updateProjectStatusAndNiraId`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                data: data
                }
            
            const xhr = await axios(config).catch(error => {
                console.log('error', error);
                throw error;
            });

            console.log('save project',xhr)

            if(xhr && xhr.data.success){
                
            }
            
        }catch(e){
            console.log('error', e)
        }

        
    }

    const downloadImage = (asset) => {
        saveAs(`${API_URL}/${RESULT_DIR}/${asset.filename}`, asset.originalname) // Put your image url here.
      }

    

    return (
        <>              
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <form className="action-link-auth">
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                    <Link className="no-link" to="/dashboard">All Projects</Link> <span> <img src={chevronImg} width="15px" /> </span> {project.name}
                                 </div>
                                <div className="project-actions-right right">
                                    <TopButtons id={id} />
                                </div>
                            </div>
                            <br />
                            <hr />
                           
                            <div className="project-left edit-assets w100">
                                <ProjectMenu active="myfiles" id={id} />
                                <div className="editWrapper">
                                    <div className="assetsContainer project-left w60">
                                        <h4>View Results</h4>
                                        <br /><br />
                                        {assets && assets.length > 0 ? (
                                            assets.map((asset)=>{
                                                return(
                                                    <div className="assetsRow" key={asset._id} id={`asset-${asset._id}`} >
                                                        <span className="thumbnail">
                                                            <Thumbnail image={asset.filename} type="results" />
                                                        </span>
                                                        <span className="assetName">{asset.originalname}</span>
                                                        <span className="assetUploaded" >
                                                            <img src={downloadIcon} width="20px"  
                                                                onClick={()=>{downloadImage(asset)}} 
                                                                
                                                                />
                                                            <img src={deleteImg} width="20px" 
                                                                onClick={()=>handleDeleteOne(asset._id)}
                                                                style={{marginLeft:'10px'}}
                                                                 />
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div>No Results has been uploaded.</div>
                                        )}
                                    </div>
                                    
                                    <div className="project-invite project-right w35">
                                        <div className="right-header">
                                            {/* <EditDetails /> */}
                                        </div>
                                    </div>
                                </div>    
                            </div>
                           
                        </div>
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Project;