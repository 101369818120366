// export a constant
// export const API_URL = 'http://dev.aren-qa.com:3009';
export const API_URL = 'https://api.aren-qa.com';
export const ASSET_DIR = 'tmp/my-uploads';
export const RESULT_DIR = 'tmp/my-results';


    export const STATUS =   [
        {
            label:'Draft',
            value:'DRAFT',
            type: ['customer', 'admin']
        },
        {
            label:'Processing',
            value:'PROCESSING',
            type: ['customer','admin']
        },
        {
            label:'hold',
            value:'HOLD',
            type: ['customer', 'admin']
        },
        {
            label:'3D Modeling Initiated',
            value:'MODELING_START',
            type: ['admin']
        },
        {
            label:'3D Model Ready for Review',
            value:'MODELING_READY_FOR_REVIEW',
            type: ['admin']
        },
        {
            label:'QA/QC: 3D Model',
            value:'MODELING_MANUAL_REVISING',
            type: ['admin']
        },
        {
            label:'3D Model Published',
            value:'MODELING_COMPLETED',
            type: ['customer', 'admin']
        },
        {
            label:'Check Input Data Resolution ',
            value:'CHECK_GSD',
            type: ['admin']
        },
        {
            label:'Ask to Reshoot Data Due to Unsatisfactory Resolution',
            value:'RESHOOTING_DATA',
            type: ['admin', 'customer']
        },
        {
            label:'Data Reshooting in Progress',
            value:'CUSTOMER_RESHOOTING',
            type: ['customer', 'admin']
        },
        {
            label:'3D Modeling Initiated ',
            value:'MODELING_INITIATED',
            type: ['admin']
        },
        {
            label:'3D Model Ready for Review  ',
            value:'MODEL_READY_FOR_REVIEW',
            type: ['admin']
        },
        {
            label:'QA/QC: 3D Model ',
            value:'QAQC_3D_MODEL',
            type: ['admin']
        },
        {
            label:'3D Model AI Detection Initiated ',
            value:'QAQC',
            type: ['admin']
        },
        {
            label:'AI Detection Initiated ',
            value:'AI_DETECTION_INITIATED',
            type: ['admin']
        },
        {
            label:'3D Model Labeling Initiated ',
            value:'MODEL_LABELING',
            type: ['admin']
        },
        {
            label:'Preparing Final Deliverables ',
            value:'PREPARING_DELIVERABLES',
            type: ['admin']
        },
        {
            label:'Completed',
            value:'COMPLETED',
            type: ['customer', 'admin']
        },
    ]


export const CUSTOMER_STATUS = (status) => {
   switch (status) {
        case STATUS.MODELING_START:
        case STATUS.MODELING_READY_FOR_REVIEW:
        case STATUS.MODELING_MANUAL_REVISING:        
            return STATUS.PROCESSING;
        case STATUS.MODELING_COMPLETED:    
        case STATUS.CHECK_GSD:
        case STATUS.QAQC:
        case STATUS.MODEL_LABELING:
        case STATUS.PREPARING_DELIVERABLES:            
            return STATUS.MODELING_COMPLETED
        case STATUS.RESHOOTING_DATA:
            return STATUS.RESHOOTING_DATA;
        case STATUS.COMPLETED:
            return STATUS.COMPLETED;    
        default:
            return status;
    }
}
