import React, { useState, useEffect } from 'react';
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { loginUser, useAuthState, useAuthDispatch } from '../context'
import Swal from 'sweetalert2'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom"


const ForgotPasswordConfirmation = (props) => {
  
    const [email, setEmail] = useState('')
    
    //get email from localstorage and use it to set email
    useEffect(() => {
        const email = localStorage.getItem('fwdpwd_email');
        setEmail(email);
    }, [])
    

     const handleBackLogin = async (e) => {
        e.preventDefault()
        props.history.push('/login')
        }

    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>
                            We’ve sent you a verification email to <span className="important link-primary">{email}</span>
                            </h1>
                            <p>Please follow the link in the email to complete the password recovery process.</p>
                            <form>                                
                                <Button 
                                    variant="secondary" 
                                    className="primary-btn margin-top-35"
                                    onClick={handleBackLogin}
                                >Back to Log In</Button>
                            </form>
                        </div>
                        <div className="no-acc margin-top-275">
                        Don’t have an account? <Link to="/Register" className="link-primary ">Join now</Link>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="home-img"></Col>
            </Row>
        </Container>
    );
};
export default ForgotPasswordConfirmation;