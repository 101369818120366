import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { loginUser, useAuthState, useAuthDispatch } from '../context'
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom"
import { API_URL } from '../constants';


const ForgotPassword = (props) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [tokenExpire, setTokenExpire] = useState(false)
    const { loading, errorMessage } = useAuthState()
    console.log('here')
    const [email, setEmail] = useState('')
    const query = new URLSearchParams(useLocation().search);
    let token = query.get("token");
    console.log('token', token);

    // useEffect(async () => {
    //     try {
    //         const config = {
    //             headers: {
    //                 'Content-Type': 'x-www-form-urlencoded',
    //             },
    //         };
    //         const url = `${API_URL}/users/validateResetPassword`;

    //         let hxr = await axios(url, {
    //             method: 'POST',
    //             headers: {
    //                 'content-type': 'application/json',
    //             },
    //             data: {
    //                 token: token
    //             }
    //         })
    //             .catch(error => {
    //                 console.log('error', error);
    //             });

    //         const data = hxr.data;
    //         console.log('data', data);

    //         if (data.success) {
    //             setTokenExpire(0)
    //         } else {
    //             setTokenExpire(1)
    //         }
    //     } catch (error) {
    //         setTokenExpire(1)
    //     }
    // }, []);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        console.log('onSubmit', data);

        const data_obj = {
            password: data.password,
            token: token
        }
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/users/resetpassword`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'Your Session has expired',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // const _data = await hxr.data;
        // console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            props.history.push('/login')
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Your Session has expired',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }


    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>Set new password</h1>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    autoComplete="off"
                                    className={`password input margin-top-35 ${errors.password ? 'is-invalid' : ''}`}
                                    placeholder="Your Password"
                                    {...register('password')}
                                // required value={password} onChange={(e) => setPassword(e.target.value)}
                                />
                                <div className="invalid-feedback">{errors.password?.message}</div>

                                <input
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    autoComplete="off"
                                    className={`password input margin-top-35 ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                    placeholder="Your Password"
                                    {...register('confirmPassword')}
                                // required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                                <Button
                                    variant="primary"
                                    className="primary-btn margin-top-35"
                                    type="submit"
                                >Update password</Button>
                            </form>
                        </div>
                        <div className="no-acc margin-top-275">
                            Don’t have an account? <Link to="/Register" className="link-primary ">Join now</Link>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="home-img"></Col>
            </Row>
        </Container>
    );
};
export default ForgotPassword;