import React, { useEffect, useState,memo } from "react";
import { API_URL, ASSET_DIR, RESULT_DIR } from '../constants';
import assetPlaceholder from '../images/projectDetails.svg';


const Thumbnails = (props) => {

    //get file extension
    // const getFileExtension = (file) => {
    //     console.log('getFileExtension ', file)
    //     return file.split('.').pop();
    // }

    // //get file type by extension
    // const getFileType = (extension) => {
    //     extension = extension.toLowerCase();
    //     switch (extension) {
    //         case 'jpg':
    //         case 'jpeg':
    //         case 'png':
    //         case 'gif':
    //             return 'image';
    //         case 'mp4':
    //         case 'mov':
    //         case 'avi':
    //         case 'flv':
    //             return 'video';
    //         case 'pdf':
    //             return 'pdf';
    //         case 'doc':
    //         case 'docx':
    //             return 'word';
    //         case 'xls':
    //         case 'xlsx':
    //             return 'excel';
    //         case 'ppt':
    //         case 'pptx':
    //             return 'powerpoint';
    //         default:
    //             return 'file';
    //     }
    // }

    // if(props.image === null || props.image === undefined || props.image === ''){
    //     return <img src={assetPlaceholder} alt="thumbnail" className="thumbnail-img" width="125px" />;
    // }

    // const file = getFileExtension(props.image);
    // const fileType = getFileType(file);
    // let url = '';
    // if(props.type=="assets"){
    //     url = `${API_URL}/${ASSET_DIR}/${props.image}`;
    // }else{
    //     url = `${API_URL}/${RESULT_DIR}/${props.image}`;
    // }

   
    
    // if(fileType == 'image'){
    //     return <img src={url} alt="thumbnail" className="thumbnail-img" /> ;
    // }else{
    //     return <img src={assetPlaceholder} alt="thumbnail" className="thumbnail-img" />;
    // }
    return <img src={assetPlaceholder} alt="thumbnail" className="thumbnail-img" />;
};
export default memo(Thumbnails);

    