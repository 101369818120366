import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import closebtn from '../images/close-btn.svg';
import profilePlaceholder from '../images/user-profile-tools.svg';
import { API_URL } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import Dropzone from '../components/dropzone';



const ProjectAssets = (props) => {
    
    let { id } = useParams();

    const [nextDisable, setNextDisable] = useState(true); 
    
      const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
      });
    // const [email, setEmail] = useState(() => {
    //     const saved = localStorage.getItem('currentUser');
    //     const initialValue = JSON.parse(saved);
    //     return initialValue.user.email || "";
    //   });
    
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('New Password is required')
            .min(6, 'Password must be at least 6 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        name: Yup.string()
            .required('Name is required')    
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    async function onSubmit(data) {
        console.log('onSubmit', data);
        console.log('user',user);
        const data_obj = {
            password: data.password,
            email: data.email,
            name: data.name,
            user: user
        }
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/users/updateUser`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // const _data = await hxr.data;
        // console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            // props.history.push('/login')
            //trigger swal  
            Swal.fire({
                title: 'Success!',
                text: 'Profile updated successfully',
                icon: 'success',
                confirmButtonText: 'OK'
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
    const dispatch = useAuthDispatch()

    const handleNext = () => {
       props.history.push(`/project/${id}/parameters`)
    }

    const [radioValue, setRadioValue] = useState('1');

    const radios = [
      { name: 'Imperial', value: '1' },
      { name: 'Metric', value: '2' },
    ];

    

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    Add Project Results
                                 </div>
                                <div className="project-actions-right right">
                                    {/* <Button disabled={nextDisable} variant="btn primary-btn-active next-btn"  onClick={handleNext}>
                                        Next
                                    </Button> */}
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className="project-full-width">
                                <Dropzone stateChanger={setNextDisable} />
                            </div>
                            <hr />
                            {/* <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step project-step-on">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ProjectAssets;