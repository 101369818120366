import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import { useAuthState, useAuthDispatch, logout } from '../context'

import thumbProfileimg from '../images/profilePictureDefault.svg';


const TopButtons = (props) => {

  const handleEdit = () => {
    window.location.href = `/project/${props.id}/edit/details`;
  }

  const handleParams = () => {
    window.location.href = `/project/${props.id}/parameters`;
  }

  const handleInvites = () => {
    window.location.href = `/project/${props.id}/invites`;
  }

  const handleEditSettings = () => {
    window.location.href = `/project/${props.id}/details`;
  }


  return (
    <>
      <Button variant="btn btnSelected next-btn" onClick={handleEditSettings}>
        Edit Project Settings
      </Button>
      <Button variant="btn btnSelected next-btn" style={{ "marginLeft": "15px" }} onClick={handleEdit}>
        View Project Details
      </Button>
      <Button variant="btn btnSelected next-btn" style={{ "marginLeft": "15px" }} onClick={handleParams}>
        View Project Params
      </Button>
      <Button variant="btn btnSelected next-btn" style={{ "marginLeft": "15px" }} onClick={handleInvites}>
        View Invites
      </Button>
    </>

  );
};
export default TopButtons;

