import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { loginUser, useAuthState, useAuthDispatch } from '../context'
import Swal from 'sweetalert2'
import { API_URL } from '../constants';


const ForgotPassword = (props) => {
    const [email, setEmail] = useState('')
    const { loading, setLoading } = useState('')

    const handleForgotRequest = async (e) => {
        e.preventDefault()
        try{
            const config = {
                headers: {
                  'Content-Type': 'x-www-form-urlencoded',
                },
              };
            const url = `${API_URL}/users/sendpassword`;
           
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                'content-type': 'application/json',
                },
                data: {
                    email: email
                }
            })
            .catch(error => {
                console.log('error', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Email not found',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
            });
            
            const data = hxr.data;
            console.log('data', data);
         
            if (data.user) {          
              localStorage.setItem('fwdpwd_email', data.user.email);
              props.history.push('/forgotpasswordconfirmation')
            }else{
                Swal.fire({
                    title: 'Error!',
                    text: 'Email not found',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
            }
        }catch(error){
            console.log('error', error)
            Swal.fire({
                title: 'Error!',
                text: 'Email not found',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        }
        
    }

    const handleBackLogin = async (e) => {
        e.preventDefault()
        props.history.push('/login')
    }

    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>Don’t worry</h1>
                            <p>Enter your email so we can continue with password recovery process.</p>
                            <form>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="email input margin-top-35"
                                    placeholder="Your Email"
                                    required value={email} onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button
                                    variant="primary"
                                    className="primary-btn margin-top-35"
                                    onClick={handleForgotRequest} disabled={loading}
                                >Continue</Button>
                                < hr />
                                <Button
                                    variant="secondary"
                                    className="primary-btn-outline margin-top-35"
                                    onClick={handleBackLogin}
                                >Back to Log In</Button>
                            </form>
                        </div>
                        <div className="no-acc margin-top-275">
                            Don’t have an account? <Link to="/Register" className="link-primary ">Join now</Link>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="home-img"></Col>
            </Row>
        </Container>
    );
};
export default ForgotPassword;