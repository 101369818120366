import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';

import { useAuthState, useAuthDispatch, logout } from '../context'

import thumbProfileimg from '../images/profilePictureDefault.svg';


const Header = (props) => {

    const userDetails = useAuthState()
    const dispatch = useAuthDispatch()

    const handleLogout = () => {
        logout(dispatch);
       window.location.href = '/login';
    }

    //load user details from local storage in react hooks 
    const [userEmail, setUserEmail] = useState("")
    const [isAdmin, setIsAdmin] = useState(false)
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('currentUser'))
        setUserEmail(userData.user.name)
        setIsAdmin( userData.user.role === 'admin' || userData.user.role === 'superAdmin' )
    }, [])



    return (
      <>        
      <Navbar className="navTopBar" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
          <Navbar.Brand href="/dashboard"><img src="/img/aren-logo.svg" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto nav-centered mx-auto">
                  <Nav.Link className={`nav-item ${props.selected!=='members' ? "header-nav-selected" : ""}`} href="/dashboard">Dashboard</Nav.Link>  
                  {!isAdmin ? '' : <Nav.Link className={`nav-item ${props.selected==='members' ? "header-nav-selected" : ""}`}  href="/aren-members">Aren Members</Nav.Link>}
                  
              </Nav>
              <Nav>
              <NavDropdown title={
                  <div className="pull-left">
                      {userEmail}
                  <img className="thumbnail-image" 
                      src={thumbProfileimg} 
                      alt="user pic"
                  />                  
              </div>                  
                } id="collasible-nav-dropdown">
                      <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                  </NavDropdown>
              </Nav>
          </Navbar.Collapse>
      </Container>
  </Navbar>
  </>
      
    );
};
export default Header;

    