import React, { useEffect, useState, createRef } from "react";
import { useDropzone } from "react-dropzone";
import uploadImage from "../images/Upload.svg";
import axios from "axios";
import { API_URL } from "../constants";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastr";
import { v4 as uuidv4 } from "uuid";

const Dropzone = ({ TYPE, ASSETID, props, ...rest }) => {
  let container;
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  const dropzoneRef = createRef();

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  // console.log('props', props);

  const action = React.useCallback(async (acceptedFiles) => {
    console.log("here");
    console.log("acceptedFiles", acceptedFiles);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uid: uuidv4(),
        })
      )
    );

    const urlAssets = `${API_URL}/projects/upload`;
    const urlResults = `${API_URL}/projects/uploadResults`;
    const urlDeliverableAssets = `${API_URL}/projects/deliverableAsset/upload`;
    const urlDeliverableCSVAssets = `${API_URL}/projects/deliverableAsset/csv`;
    const urlDeliverableDamageImages = `${API_URL}/projects/deliverableAsset/damages`;
    const urlDeliverableAllDamageImage = `${API_URL}/projects/deliverableAsset/alldamages`;

    let url = "";
    console.log("type", TYPE);
    if (TYPE === "assets") {
      url = urlAssets;
    } else if (TYPE === "deliverableAssets") {
      url = urlDeliverableAssets;
    } else if (TYPE === "deliverableCSVAssets") {
      url = urlDeliverableCSVAssets;
    } else if (TYPE === "deliverableDamageImages") {
      url = urlDeliverableDamageImages;
    } else if (TYPE === "deliverableAllDamageImage") {
      url = urlDeliverableAllDamageImage;
    } else {
        url = urlResults;
      }

    console.log("url", url);

    let requestArr = [];

    await asyncForEach(acceptedFiles, async (file) => {
      console.log("file", file);
      var formData = new FormData();
      formData.append("file", file);
      formData.append("projectId", id);

      if (
        TYPE === "deliverableAssets" ||
        TYPE === "deliverableCSVAssets" ||
        TYPE === "deliverableDamageImages" ||
        TYPE === "deliverableAllDamageImage"
      ) {
        formData.append("id", ASSETID);
      }
      console.log("type", TYPE);
      console.log("ASSETID", ASSETID);

      console.log("formData", formData);

      requestArr.push(
        axios
          .post(url, formData)
          .then((rst) => {
            console.log("rst", rst);
            var elem = document.getElementById(file.uid);
            var html = elem.innerHTML;
            elem.innerHTML = `<span class="upload-success" >Uploaded</span>`;
          })
          .catch((error) => {
            console.log("error", error);
            var elem = document.getElementById(file.uid);
            var html = elem.innerHTML;
            elem.innerHTML = `<span class="upload-error" >Error uploading</span>`;
          })
      );
    });

    Promise.all(requestArr).then(() => {
      console.log("resolved promise.all");
      // stateChanger(false)
    });
  });

  let { id } = useParams();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    // accept: 'image/*, video/*, .csv, application/vnd.ms-excel, text/csv',
    onDrop: action,
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumbList">
        {file.path} - {file.size} bytes -{" "}
        <span id={file.uid}>
          <span className="upload-pending">Uploading</span>
        </span>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <div {...getRootProps({ className: "dropzone" })} ref={dropzoneRef}>
        <input {...getInputProps()} />
        <img src={uploadImage} />
        <br />
        <p>Drag and drop your files, or click the button to upload</p>
        <span className="btn primary-btn-active width50">
          Upload from Computer
        </span>
      </div>
      <aside>{thumbs}</aside>
    </section>
  );
};

export default Dropzone;
