import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/header';
import { API_URL, STATUS } from '../constants';
import { useAuthState, useAuthDispatch, logout } from '../context';
import ProjectMenu from '../components/projectMenu';
import chevronImg from '../images/chevron.svg';

import RenderImage from '../components/renderImages';
console.log('STATUS',STATUS)
const EditProjectDetails = (props) => {

    let { id } = useParams(); 
    const [projectId, setProjectId] = useState("");
    const [isProjectGuest, setIsProjectGuest] = useState(false);
    const [assets, setAssets] = useState([]);
    const [project, setProject] = useState([]);
    const [projectName, setProjectName] = useState([]);
    const [projectAddress, setProjectAddress] = useState([]);
    const [projectCity, setProjectCity] = useState([]);
    const [projectState, setProjectState] = useState([]);
    const [projectCountry, setProjectCountry] = useState([]);

    const [email, setEmail] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });

    const handleLogout = () => {        
       window.location.href = '/login';
    }

    const handleEdit = () => {        
        window.location.href = `/project/${id}/edit/details`;
     }


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')   
            .min(3, 'Name must be at least 3 characters')
            .max(50, 'Name must be less than 50 characters'),
        country: Yup.string()
            .required('Country is required'),
        state: Yup.string()
            .required('State is required'),
        city: Yup.string()
            .required('City is required')       
    });

    const formOptions = { defaultValues: validationSchema.cast(),resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    

    useEffect(() => {
        setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr)
            setProject(hxr.data.project)
            setProjectName(hxr.data.project.name)
            setProjectAddress(hxr.data.project.address)
            setProjectCity(hxr.data.project.city)
            setProjectState(hxr.data.project.state)
            setProjectCountry(hxr.data.project.country)
            setIsProjectGuest(email !== hxr.data.project.createdBy);
        }

        getProjectById();

    }, [])

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(project);
    }, [project]);


   

    async function onSubmit(data) {
        // console.log('user',user);
        const data_obj = {
            name: data.name,
            address: data.address,
            country: data.country,
            city:  data.city,
            state:  data.state,
            id: projectId
        }

        console.log('data_obj', data_obj);
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/projects/updateProject`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        const _data = await hxr.data;
        console.log('data', _data);

        if (hxr.data && hxr.data.success) {
            window.location.reload(true);
            
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
    
    

    const parseAssetType = (assetId) => {
        let type = '-';
        switch(assetId){
            case '1':
                type = 'Bridge';
                break; 
            case '2':
                type = 'Building Facade';
            break; 
            case '3':
                type = 'Cooling Tower';
            break; 
            case '4':
                type = 'Industrial';
                break; 
            case '5':
                type = 'Dam';
                break; 
            case '6':
                type = 'Levee';
                break; 
            case '7':
                type = 'Culvert';
                break;     
               
        }
        return type;
    }

    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                    <form onSubmit={handleSubmit(onSubmit)} className="edit-form">
                        <div className="project-dashboard left w100">
                            <div className="profile-actions">
                                <div className="project-actions-left left padding10px"> 
                                <Link className="no-link" to="/dashboard">All Projects</Link> <span> <img src={chevronImg} width="15px" /> </span>  {projectName} <span> <img src={chevronImg} width="15px" /> </span>  Project Details
                                 </div>
                                <div className="project-actions-right right">
                                {!isProjectGuest ? (
                                    <Button type="submit" variant="btn primary-btn-active next-btn">
                                        Save Changes
                                    </Button>
                                ) : "" }    
                                </div>
                            </div>
                            <br />
                            <hr />
                           
                            <div className="project-left edit-project w50 left">
                                <div className="projectTypeImages w100">
                                    <RenderImage  projectType={project.assetsType}/>
                                </div>
                                <br />
                                <hr />
                                <br />                                
                                <h3>{project.name}</h3> 
                                                            
                                <div className="project-property-row w100 ">
                                    <div className="project-property-title w35 displayInlineBlock">Type</div>
                                    <div className="project-property-value w65 displayInlineBlock">{parseAssetType(project.assetsType)}</div>
                                </div>
                                <div className="project-property-row w100">
                                    <div className="project-property-title w35 displayInlineBlock">Stage</div>
                                    <div className="project-property-value w65 displayInlineBlock">{project.status}</div>
                                </div>
                                <div className="project-property-row w100">
                                    <div className="project-property-title w35 displayInlineBlock">Date</div>
                                    <div className="project-property-value w65 displayInlineBlock">{project.createdDate}</div>
                                </div>
                            </div>
                            <div className="project-left edit-assets w50 left">
                            
                                    <div className="project-dashboard no-border marginTopM25">     
                                        <div className="form-group">
                                            <input type="hidden" name="id" defaultValue={id} />
                                            <label htmlFor="name" className="label-custom">Project Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                onChange={(e) => setProjectName(e.target.value)}
                                                autoComplete="off"
                                                className={`input ${errors.name ? 'is-invalid' : ''}`}
                                                disabled={isProjectGuest}
                                                // placeholder="Your Password"
                                                {...register('name')}
                                            // required value={password} onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <div className="invalid-feedback">{errors.name?.message}</div>
                                        </div>      
                                        <div className="form-group">
                                            <label htmlFor="address" className="label-custom">Address</label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                defaultValue={projectAddress}
                                                onChange={(e) => setProjectAddress(e.target.value)}
                                                autoComplete="off"
                                                className={`input ${errors.address ? 'is-invalid' : ''}`}
                                                disabled={isProjectGuest}
                                                {...register('address')}
                                            // required value={password} onChange={(e) => setPassword(e.target.value)}
                                            />
                                            {/* <div className="invalid-feedback">{errors.address?.message}</div> */}
                                        </div> 
                                        <div className="form-group">
                                            <label htmlFor="city" className="label-custom">City</label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                autoComplete="off"
                                                defaultValue={projectCity}
                                                onChange={(e) => setProjectCity(e.target.value)}
                                                className={`input ${errors.city ? 'is-invalid' : ''}`}
                                                disabled={isProjectGuest}
                                                // placeholder="Your Password"
                                                {...register('city')}
                                            // required value={password} onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <div className="invalid-feedback">{errors.city?.message}</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="state" className="label-custom">State</label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                defaultValue={projectState}
                                                onChange={(e) => setProjectState(e.target.value)}
                                                autoComplete="off"
                                                className={`input ${errors.state ? 'is-invalid' : ''}`}
                                                disabled={isProjectGuest}
                                                {...register('state')}
                                            // required value={password} onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <div className="invalid-feedback">{errors.state?.message}</div>
                                        </div>                             
                                        <div className="form-group">
                                            <label htmlFor="country" className="label-custom">Country</label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                defaultValue={projectCountry}
                                                onChange={(e) => setProjectCountry(e.target.value)}
                                                autoComplete="off"
                                                className={`input ${errors.country ? 'is-invalid' : ''}`}
                                                disabled={isProjectGuest}
                                                {...register('country')}
                                            />
                                            <div className="invalid-feedback">{errors.country?.message}</div>
                                        </div>                                                               
                                    </div>
                                {/* </form>   */}
                            </div>
                            {/* <hr />
                            <div className="project-dashboard-footer">
                                <div className="project-footer-left project-footer-column">
                                    <span className="project-step project-step-active">1</span>
                                    <span className="project-step-text">Add Project details</span>
                                </div>
                                <div className="project-footer-middle project-footer-column">
                                    <span className="project-step">2</span>
                                    <span className="project-step-text">Upload your files</span>
                                </div>
                                <div className="project-footer-right project-footer-column">
                                    <span className="project-step">3</span>
                                    <span className="project-step-text">Set Parameters</span>
                                </div>
                            </div> */}
                        </div>
                    </form>    
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default EditProjectDetails;