import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../../components/header';
import profilePlaceholder from '../../images/user-profile-tools.svg';
import { API_URL } from '../../constants';
import backarrow from '../../images/backarrow.svg';
import { useParams } from 'react-router-dom';
import { stubTrue } from 'lodash';

const MemberEdit = () => {
    const [name, setName] = useState('');     
    const [email, setEmail] = useState('');
    const [memberSelected, setMemberSelected] = useState('');
    const [user, setUser] = useState('');

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    let { id } = useParams(); 

    useEffect(() => {        
        async function getAdminUser() {
            const data_obj = {
                id: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/admin/private/getAdminById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })        

            console.log('hxr.data', hxr.data)

            if(hxr.data.success){
                setUser(hxr.data.admin)
                setName(hxr.data.admin.name)
                setEmail(hxr.data.admin.email)
                setMemberSelected(hxr.data.admin.role)
            }
            
        }
        getAdminUser();
    }, [])

    async function onSubmit(data) {

        const data_obj = {
            email: email,
            name: name,
            role: memberSelected
        }

        console.log('data_object',data_obj)
        const config = {
            headers: {
                'Content-Type': 'x-www-form-urlencoded',
            },
        };
        const url = `${API_URL}/admin/private/updateUserWithoutPassword`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: data_obj
        })
        .catch(error => {
            console.log('error', error);
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        });

        // // const _data = await hxr.data;
        // // console.log('data', _data);

        if (hxr?.data && hxr.data.success) {
            // localStorage.setItem('fwdpwd_email', data.user.email);
            // props.history.push('/login')
            //trigger swal  
            Swal.fire({
                title: 'Success!',
                text: 'Aren Member successfully updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/aren-members';
                }
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'An error occured, please try again later',
                icon: 'error',
                confirmButtonText: 'OK'
            })
        }

    }
   

    const handleGotoDashboard = () => {
       window.location.href = '/aren-members';
    }

    

    const handleChangeMember = async (e)=>{
        console.log('here', e)
        setMemberSelected(e);
                
    }


    return (
        <>
            <Header selected='members' />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                            <div className="profile-actions">
                                <div className="project-actions-left left"> 
                                    <Button variant="link" className="danger-link" onClick={handleGotoDashboard}><img src={backarrow} width="30px" /></Button>
                                 </div>
                                <div className="project-actions-right right">
                                    {/* <Button className="close-btn" onClick={handleLogout}>
                                        <img src={closebtn} />
                                    </Button> */}
                                </div>
                            </div>
                            <div className="profile-center user-create">
                                <h2 >Update AREN Member</h2>
                                <br />
                                <form onSubmit={handleSubmit(onSubmit)} className="action-link-auth">
                                    <div className="form-group">
                                        <label htmlFor="name" className="label-custom">Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="off"
                                            className={`input ${errors.name ? 'is-invalid' : ''}`} 
                                            value={user.name}
                                            onChange={(e) => setName(e.target.value)}
                                        
                                        />
                                        <div className="invalid-feedback">{errors.name?.message}</div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email" className="label-custom">Aren Email</label>
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            defaultValue={user.email}
                                            autoComplete="off"
                                            disabled={true}
                                            className={`input ${errors.email ? 'is-invalid' : ''}`} 
                                            value={user.email}                                         
                                        />
                                    </div>

                                    <ButtonGroup className='member-assign'>
                                        <ToggleButton
                                            id={`radio-member`}
                                            type="radio"
                                            variant="outline-warning"
                                            name="radio"
                                            value="member"
                                            checked={(memberSelected === 'member' || memberSelected === '' ) ? true : false}
                                            onChange={(e) =>{
                                                 handleChangeMember(e.currentTarget.value)
                                                }
                                            }
                                        >
                                            Member
                                        </ToggleButton>
                                        <ToggleButton
                                            id={`radio-admin`}
                                            type="radio"
                                            variant="outline-warning"
                                            name="radio"
                                            value="admin"
                                            checked={(memberSelected === 'admin' || memberSelected === 'superAdmin' ) ? true : false}
                                            onChange={(e) =>{
                                                handleChangeMember(e.currentTarget.value)
                                                }
                                            }
                                        >
                                            Admin
                                        </ToggleButton>
                                    </ButtonGroup>
                                    
                                    <Button
                                        variant="primary"
                                        className="primary-btn update-profile-btn"
                                        type="submit"
                                    >Edit</Button>
                                    <hr />
                                    
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default MemberEdit;