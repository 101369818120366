import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Header from "../components/header";
import chevronImg from "../images/chevron.svg";
import { API_URL } from "../constants";
import ProjectMenu from "../components/projectMenu";
import TopButtons from "../components/topButtons";

const ProjectSettings = () => {
  let { id } = useParams();
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState([]);
  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue?.user?.email || "";
  });
  const [isProjectGuest, setIsProjectGuest] = useState(false);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(""); // New state to track selected email
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [currentOwner, setCurrentOwner] = useState("");
  const [success, setSuccess] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState();
  const [fadeOut, setFadeOut] = useState(false);

  const alertStyle = {
    color: "#0f5132",
    backgroundColor: "#d1e7dd",
    borderColor: "#badbcc",
    position: "relative",
    padding: "1rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: ".25rem",
  };

  const containerStyle = {
    marginTop: "1rem",
  };

  useEffect(() => {
    if (query.length >= 2) {
      setIsLoading(true);
      fetch(`${API_URL}/users/autocomplete?email=${query}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setUsers(data.users);
          } else {
            setUsers([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
          setIsLoading(false);
        });
    } else {
      setUsers([]);
      setShowSuggestions(false);
    }
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setSelectedEmail(""); // Clear selected email when typing
    if (e.target.value.length >= 2) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSaveClick = (email) => {
    setQuery(email);
    setSelectedEmail(email); // Set the selected email
    setShowSuggestions(false); // Close the suggestions list
    setUsers([]); // Clear the user list
  };

  const handleClearClick = () => {
    setQuery("");
    setSelectedEmail(""); // Clear the selected email
    setShowSuggestions(false); // Close the suggestions list
    setUsers([]); // Clear the user list
  };

  useEffect(() => {
    setProjectId(id);
    async function getProjectById() {
      const data_obj = { projectid: id };
      const url = `${API_URL}/projects/getProjectById`;

      try {
        const response = await axios.post(url, data_obj, {
          headers: { "content-type": "application/json" },
        });
        setProject(response.data.project);
        setCurrentProjectId(response.data.project._id)
        setCurrentOwner(response.data.project.createdBy);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    }
    getProjectById();
  }, [id, email]);

  const handleUpdateEmail = async () => {
    try {
      const response = await axios.post(`${API_URL}/users/ownership`, {
        projectId: currentProjectId,
        newEmail: selectedEmail,
      });
      console.log('response', response);
      if (response.data.success) {
        setSuccess(true)
        setCurrentOwner(selectedEmail)
      }
    } catch (error) {
      console.error("There was an error updating the email:", error);
    }
  };

  useEffect(() => {
    if (success) {
      const fadeOutTimer = setTimeout(() => {
        setFadeOut(true); // Start fade out
      }, 4000); // Start fade-out after 4 seconds

      const hideAlertTimer = setTimeout(() => {
        setSuccess(false); // Hide alert after fade out
        setFadeOut(false); // Reset fade out state
        setSelectedEmail(""); // Clear selected email to hide the button
      }, 5000); // Completely hide alert after 5 seconds

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(hideAlertTimer);
      };
    }
  }, [success]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col md="12">
            <form className="action-link-auth">
              <div className="project-dashboard">
                <div className="profile-actions">
                  <div className="project-actions-left left padding10px">
                    <Link className="no-link" to="/dashboard">
                      All Projects
                    </Link>
                    <span>
                      <img src={chevronImg} width="15px" alt="chevron" />
                    </span>
                    {project.name}
                  </div>
                  <div className="project-actions-right right">
                    <TopButtons id={id} />
                  </div>
                </div>
                <br />
                <hr />
                <div className="project-left edit-assets w100">
                  <ProjectMenu active="settings" id={id} />
                  <div className="editWrapper">
                    <div className="assetsContainer project-left w60">
                      <h4>Edit Project Settings</h4>
                      <hr />
                      <h6 style={{ marginTop: "30px" }}>
                        Current Owner:{" "}
                        <span style={{ marginLeft: "15px" }}>
                          {currentOwner}
                        </span>
                      </h6>
                      <hr />
                      <h5>Transfer ownership</h5>

                      <div style={{ marginTop: "30px", position: "relative" }}>
                        <div className="form-group">
                          <label htmlFor="name" className="label-custom">
                            Owner
                          </label>
                          <input
                            type="text"
                            placeholder="Search by email..."
                            value={query}
                            onChange={handleInputChange}
                            className="input"
                          />
                          {selectedEmail && !success && (
                            <button
                              type="button"
                              className="btn btn-btn primary-btn-active next-btn"
                              style={{ marginTop: "10px" }}
                              onClick={handleUpdateEmail}
                            >
                              Transfer
                            </button>
                          )}
                          <button
                            type="button"
                            onClick={handleClearClick}
                            style={{
                              right: "20px",
                              position: "absolute",
                              top: "10px",
                              border: "none",
                              background: "none",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="white"
                              width="20px"
                              height="20px"
                              className="size-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          {isLoading ? (
                            <p>Loading...</p>
                          ) : (
                            showSuggestions && (
                              <ul
                                style={{
                                  listStyle: "none",
                                  margin: "25px 0",
                                  padding: "0",
                                }}
                              >
                                {users.map((user) => (
                                  <li
                                    key={user._id}
                                    onClick={() => handleSaveClick(user.email)}
                                    style={{
                                      cursor: "pointer",
                                      margin: "5px 0",
                                      borderBottom: "1px solid #636363",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    {user.email}
                                  </li>
                                ))}
                              </ul>
                            )
                          )}
                        </div>
                        {success && (
                            <div style={containerStyle}>
                            <div
                                className="alert alert-success"
                                role="alert"
                                style={alertStyle}
                            >
                            Ownership transfer with success
                            </div>
                            </div>
                        )}
                      </div>
                    </div>

                    <div className="project-invite project-right w35">
                      <div className="right-header">
                        {/* <EditDetails /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProjectSettings;
