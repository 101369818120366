import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import Header from '../../components/header';
import { API_URL } from '../../constants';
import ShieldImg from '../../images/shield-icon.svg';
import DeleteImg from '../../images/delete.svg';
import EditImg from '../../images/editIcon.svg';
import Swal from 'sweetalert2'


const Members = (props) => {
    
    const [members, setMembers] = useState([]);

    useEffect(() => {
        async function getAllMembers() {
            
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/admin/`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                }
            })
            console.log('admins hxr',hxr)
            setMembers(hxr.data.users);
        }

        getAllMembers();

    }, [])

    const handleDelete = async (id, name) => {
        console.log('id', id)
        Swal.fire({
            title: `Remove ${name}?`,
            text: "Are you sure you want to remove this member? \n This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#fdc20f',
            confirmButtonText: 'Remove',
            customClass: "remove-member-modal"
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/admin/private/deleteUser`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                Swal.fire(
                    'Deleted!',
                    'This User has been deleted.',
                    'success'
                  )
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            }
          })
    }

    const handleResetPassword = async (id, name, email) => {
        console.log('id', id)
        Swal.fire({
            title: `Reset ${name}'s password?`,
            text: "Are you sure you want to reset this password? \n This action cannot be undone.",
            showCancelButton: true,
            confirmButtonColor: '#fdc20f',
            cancelButtonColor: '#fdc20f',
            confirmButtonText: 'Reset',
            customClass: "remove-member-modal"
          }).then(async (result) => {
            if (result.isConfirmed) {
        
                const data_obj = {
                    id: id,
                    name: name,
                    email: email
                }
                console.log('data_obj', data_obj);
                const config = {
                    headers: {
                        'Content-Type': 'x-www-form-urlencoded',
                    },
                };
                const url = `${API_URL}/admin/private/internalResetPassword`;
        
                let hxr = await axios(url, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    data: data_obj
                })
                Swal.fire(
                    'Reset!',
                    'This Password has been reset.',
                    'success'
                  )
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            }
          })
    }

    

    const SuperAdmin = ()=>{
        return (
            <span className='admin-row-icon'>
              <span className='member-txt'>Super Admin</span>
            </span>
        )
    }

    const Member = ()=>{
        return (
            <span className='member-row-icon'>
              <span className='member-txt'>Member</span>
            </span>
        )
    }

    const Admin = ()=>{
        return (
            <span className='admin-row-icon'>
              <img src={ShieldImg} alt="admin" />
              <span  className='member-txt'>Admin</span>
            </span>
        )
    }

    const ShowUserType = (props) => {
        if(props.userType === 'superadmin' || props.userType === 'admin'){
            return <Admin />
        }else{
            return <Member />
        }
    }

    const handleGotoCreate = () => {
        window.location.href = '/aren-members/create';
     }

     const handleGotoEdit = (id) => {
        window.location.href = `/aren-members/${id}/edit`;
     } 


    return (
        <>
            <Header selected='members' />
            <Container >                
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                        <div className="project-dashboard-actions">
                                <div className="project-actions-left left padding10px"> 
                                    Aren Members
                                 </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" onClick={()=>handleGotoCreate()}  >
                                        Add New Member
                                    </Button>
                                </div>
                        </div>
                        <div>
                        </div>
                            
                            <Table>
                                <thead>
                                    <tr className="project-rows">
                                        <th>Member Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="project-tablebody">
                                    {
                                        members.map((member, index) => {
                                            return (
                                                <tr key={index} className="project-rows">
                                                    <td>{member.name}</td>
                                                    <td>{member.email}</td>
                                                    <td>
                                                        <ShowUserType  userType={member.role} />                                                        
                                                    </td>
                                                    <td>
                                                        {(member.role === 'superadmin' || member.role === 'admin') ? '' : 
                                                            <img className='delete float-left' src={DeleteImg} alt="delete" onClick={()=>handleDelete(member._id, member.name)} />}                                                       
                                                        {/* <img className='delete' width="25px" src={EditImg} alt="delete" onClick={()=>handleDelete(member._id, member.name)} /> */}
                                                        <span onClick={()=>handleGotoEdit(member._id)} className="material-symbols-outlined edit-icon float-left">edit_square</span>
                                                        <span onClick={()=>handleResetPassword(member._id, member.name, member.email)} className="material-symbols-outlined edit-icon float-left">password</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                              
                                </tbody> 
                            </Table>

                            
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Members;