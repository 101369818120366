import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';


const ProjectMenu = (props) => {


    const handleViewResults = () => {        
        window.location.href = `/project/${props.id}/edit`;
    }

    const handleUploadResults= () => {        
        window.location.href = `/project/${props.id}/uploadresults`;
    }

    const handleDeliverables = () => {        
        window.location.href = `/project/${props.id}/uploadassets`;
    }
    const handleViewassets = () => {        
        window.location.href = `/project/${props.id}/viewassets`;
    }
    const handleViewConfig = () => {        
        window.location.href = `/project/${props.id}/configDeliverables`;
    }
    const handleSettingsConfig = () => {        
        window.location.href = `/project/${props.id}/settings`;
    }


    return (
        <>
        <ButtonGroup aria-label="Basic example" className="toolbarAbs" style={{width: '100%'}}>
            <Button variant="secondary" className={`backgroundGray ${props.active=='myfiles' ? "btnSelected" : ""}`} onClick={handleViewResults}> View Deliverables</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='results' ? "btnSelected" : ""}`} onClick={handleUploadResults}>Upload Deliverables</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='assets' ? "btnSelected" : ""}`} onClick={handleViewassets}>View Uploads</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='assetsupload' ? "btnSelected" : ""}`} onClick={handleDeliverables}>Upload Files</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='config' ? "btnSelected" : ""}`} onClick={handleViewConfig}>Configure Deliverables</Button>
            <Button variant="secondary" className={`backgroundGray ${props.active=='settings' ? "btnSelected" : ""}`} onClick={handleSettingsConfig}>Project Settings</Button>
        </ButtonGroup>
        </>
      
    );
};
export default ProjectMenu;

    