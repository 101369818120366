import React, { useState, useEffect } from 'react';
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
const Home = () => {
    // const [state, setState] = useState([])
    // useEffect(async () => {
    //     const xhr = await axios('http://localhost:3009/blog/post');
    //     setState(xhr.data.posts)
    // }, [])

    return (
        <Container fluid>
            <Row>
                <Col md="3" >
                    <div className="auth-info">
                        <div className="logo">
                            <img src="/img/aren-logo.svg" />
                        </div>
                        <div className="re-box">
                            <h1>Welcome to Aren</h1>
                            <form>
                                <input type="email" name="email" id="email" className="email input margin-top-35" placeholder="Your Email"/>
                                <input type="password" name="password" id="password" className="password input margin-top-35" placeholder="Your Password"/>
                                <p>Forgot Password? <a href="#">Reset now</a></p>
                                <Button variant="primary" className="primary-btn margin-top-35">Login</Button>
                            </form>
                        </div>
                        <div className="no-acc">
                        Don’t have an account? <a href="#">Join now</a>
                        </div>
                    </div>
                </Col>
                <Col md={{ span: 8, offset: 1 }} className="home-img"></Col>
            </Row>
        </Container>
    );
};
export default Home;