import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const AddPost = (props) => {

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const handleSubmit = async (e) => {        
        e.preventDefault();

        
        const config = {
            method: 'post',
            url: 'http://localhost:3009/blog/post',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("currentUser")).auth_token}`
            },
            data: {
                title: title,
                content: content
              }
            }
        const xhr = await axios(config).catch(error => {
            console.log('error', error);
            throw error;
        });

        window.location.href = '/my-posts'
        
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1 style={{ margin: '25px auto'}}>Add Post</h1>
                    <Form style={{ margin: '25px auto'}}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Post Title" required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Write your post</Form.Label>
                            <Form.Control as="textarea" rows={15} required value={content} onChange={(e) => setContent(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default AddPost;