import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Header from "../components/header";
import { API_URL, STATUS } from "../constants";
import {
  FilterStatus,
  FlatArray,
  GetCurrentStatus,
  MatchStatus,
} from "../utilities";

const ProjectDetails = (props) => {
  const [user, setUser] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });

  const [statusList, setStatusList] = useState([]);

  const [assets, setAssets] = useState([]);
  const [niraid, setNiraid] = useState([]);
  const [labeledNiraId, setLabeledNiraid] = useState([]);
  const [labeledNiraIdSecond, setLabeledNiraidSecond] = useState([]);
  const [status, setStatus] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [showLegacyMode, setShowLegacyMode] = useState(true);
  const [showLegacyModeValue, setShowLegacyModeValue] = useState("");

  const [niraIdType1Value, setNiraIdType1Value] = useState("a");
  const [niraIdType2Value, setNiraIdType2Value] = useState("a");
  const [niraIdType3Value, setNiraIdType3Value] = useState("a");

  const [labeledNiraIdText, setLabeledNiraidText] = useState([]);
  const [labeledNiraIdSecondText, setLabeledNiraidSecondText] = useState([]);

  let { id } = useParams();

  useEffect(() => {
    setProjectId(id);
    
    async function getProjectById() {
      const data_obj = {
        projectid: id,
      };
      console.log("data_obj", data_obj);
      const config = {
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
      };
      const url = `${API_URL}/projects/getProjectById`;

      let hxr = await axios(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: data_obj,
      });

      console.log("hxr.data.project.status", hxr.data.project.status);
      console.log("hxr.data.project", hxr.data.project);
      let parsedStatus = [];
      if (!hxr.data.project.status || hxr.data.project.status == "DRAFT") {
        parsedStatus = STATUS;
      } else {
        const flatArray = FlatArray(FilterStatus(STATUS, "admin"));
        // console.log("flatArray", flatArray);

        const _status =
          hxr.data.project.status === hxr.data.project.status.toUpperCase()
            ? hxr.data.project.status
            : hxr.data.project.status.toUpperCase();
        // console.log("_status", _status);
        const getCurrentStatus = GetCurrentStatus(flatArray, _status);
        // console.log("getCurrentStatus", getCurrentStatus);
        parsedStatus = MatchStatus(getCurrentStatus);
        // console.log("parsedStatus", parsedStatus);
      }
      const isLegacy = hxr.data.project.isNiraNew ? 'v1' : 'legacy';
      setShowLegacyModeValue(isLegacy)
      setShowLegacyMode(isLegacy === 'legacy' ? true : false)
      setStatusList(parsedStatus);
      setNiraid(hxr.data.project.niraid);
      setStatus(hxr.data.project.status);
      setLabeledNiraid(hxr.data.project.labeledNiraId);
      setLabeledNiraidSecond(hxr.data.project.labeledNiraIdSecond);
      setLabeledNiraidText(hxr.data.project.labeledNiraIdText);
      setLabeledNiraidSecondText(hxr.data.project.labeledNiraIdSecondText);
      setNiraIdType1Value(hxr.data.project.niraidType);
      setNiraIdType2Value(hxr.data.project.labeledNiraIdType);
      setNiraIdType3Value(hxr.data.project.labeledNiraIdSecondType);

      console.log('from state', niraIdType1Value)

    }
    getProjectById();
  }, []);

  const handleSubmitProjectDetails = async (e) => {
    e.preventDefault();

    try {
      const data = {
        projectid: id,
        niraid: niraid,
        status: status,
        labeledNiraId: labeledNiraId,
        labeledNiraIdSecond: labeledNiraIdSecond,
        labeledNiraIdText: labeledNiraIdText,
        labeledNiraIdSecondText: labeledNiraIdSecondText,
        user: user,
        niraIdType1: niraIdType1Value,
        niraIdType2: niraIdType2Value,
        niraIdType3: niraIdType3Value,
        legacyMode: showLegacyModeValue,
      };
      //   console.log('data',data)
      const config = {
        method: "post",
        url: `${API_URL}/projects/updateProjectStatusAndNiraId`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: data,
      };

      const xhr = await axios(config).catch((error) => {
        console.log("error", error);
        throw error;
      });

      console.log("save project", xhr);

      if (xhr && xhr.data.success) {
        window.location.reload();
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleClick = () => {
    window.location = `/project/${id}/edit`;
  };

  const handleShowLegacyMode = (legacyValue) => {
    if (legacyValue === "legacy") {
      setShowLegacyMode(true);
      setShowLegacyModeValue("legacy");
    } else {
      setShowLegacyMode(false);
      setShowLegacyModeValue("v1");
    }
  };


  // console.log('selectedDamages', selectedDamages)
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col md="12">
            <form>
              <div className="project-dashboard parameters">
                <div className="profile-actions">
                  <div className="project-actions-left left">
                    Project Settings
                  </div>
                  <div className="project-actions-right right">
                    <Button
                      variant="btn primary-btn-active next-btn"
                      onClick={handleClick}
                    >
                      Back
                    </Button>
                  </div>
                </div>
                <br />
                <hr />
                <div className="">
                  <span className="damage-title">Labeled 3D Model Type</span>
                  <br />
                  <br />

                  <div className="form-group">
                    <Form.Select
                      size="lg"
                      className="custom-select"
                      name="assetType"
                      value={showLegacyModeValue}
                      onChange={(e) => handleShowLegacyMode(e.target.value)}
                    >
                      <option key="legacy" value="legacy">
                        Legacy
                      </option>
                      <option key="v1" value="v1">
                        V1
                      </option>
                    </Form.Select>
                    <br />
                    <label
                      htmlFor="nira"
                      className="label-custom-form margin-top-35"
                    >
                      Status
                    </label>
                    <br />
                    <Form.Select
                      size="lg"
                      className="custom-select"
                      name="assetType"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                     
                      {statusList.map((item, index) => {
                        return item.type.includes("admin") ? (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ) : (
                          ""
                        );
                      })}
                    </Form.Select>

                    <br />
                    <hr />
                    <br />
                    <label
                      htmlFor="nira"
                      className="label-custom-form margin-top-35"
                    >
                      Nira Id
                    </label>
                    <input
                      type="text"
                      name="nira"
                      id="nira"
                      className="email input"
                      placeholder="-"
                      required
                      value={niraid}
                      onChange={(e) => setNiraid(e.target.value)}
                      // onKeyPress={handleKeyPress}
                    />
                    <Form.Select
                      size="lg"
                      className="custom-select select-type"
                      name="assetTypeLabeled2"
                      value={niraIdType1Value}
                      onChange={(e) => setNiraIdType1Value(e.target.value)}
                    >
                      <option key="g" value="g">
                        Type G
                      </option>
                      <option key="a" value="a">
                        Type A
                      </option>
                    </Form.Select>
                    <br />
                    <hr />
                    <br />
                    {showLegacyMode && (
                      <>
                        <label
                          htmlFor="labeledNira"
                          className="label-custom-form margin-top-35"
                        >
                          Labeled 3D Model No.1
                        </label>
                        <input
                          type="text"
                          name="labeledNira"
                          id="labeledNira"
                          className="email input "
                          placeholder="-"
                          required
                          value={labeledNiraId}
                          onChange={(e) => setLabeledNiraid(e.target.value)}
                          // onKeyPress={handleKeyPress}
                        />

                        <Form.Select
                          size="lg"
                          className="custom-select select-type"
                          name="assetTypeLabeled1"
                          value={niraIdType2Value}
                          onChange={(e) => setNiraIdType2Value(e.target.value)}
                        >
                          <option key="g" value="g">
                            Type G
                          </option>
                          <option key="a" value="a">
                            Type A
                          </option>
                        </Form.Select>
                        <br />
                        <hr />
                        <br />
                        <label
                          htmlFor="nira"
                          className="label-custom-form margin-top-35"
                        >
                          Labeled 3D Model No.2
                        </label>
                        <input
                          type="text"
                          name="labeledNiraIdSecond"
                          id="labeledNiraIdSecond"
                          className="email input"
                          placeholder="-"
                          required
                          value={labeledNiraIdSecond}
                          onChange={(e) =>
                            setLabeledNiraidSecond(e.target.value)
                          }
                          // onKeyPress={handleKeyPress}
                        />
                        <Form.Select
                          size="lg"
                          className="custom-select select-type"
                          name="assetTypeLabeled2"
                          value={niraIdType3Value}
                          onChange={(e) => setNiraIdType3Value(e.target.value)}
                        >
                          <option key="g" value="g">
                            Type G
                          </option>
                          <option key="a" value="a">
                            Type A
                          </option>
                        </Form.Select>

                        <br />
                        <hr />
                        <br />

                        <span className="damage-title">Tab Text</span>
                        <br />
                        <br />
                        <label
                          htmlFor="labeledNira"
                          className="label-custom-form margin-top-35"
                        >
                          Labeled 3D Model No.1 Text
                        </label>
                        <input
                          type="text"
                          name="labeledNira"
                          id="labeledNira"
                          className="email input "
                          placeholder="-"
                          required
                          value={labeledNiraIdText}
                          onChange={(e) => setLabeledNiraidText(e.target.value)}
                          // onKeyPress={handleKeyPress}
                        />
                        <br />
                        <br />
                        <label
                          htmlFor="nira"
                          className="label-custom-form margin-top-35"
                        >
                          Labeled 3D Model No.2 Text
                        </label>
                        <input
                          type="text"
                          name="labeledNiraIdSecond"
                          id="labeledNiraIdSecond"
                          className="email input"
                          placeholder="-"
                          required
                          value={labeledNiraIdSecondText}
                          onChange={(e) =>
                            setLabeledNiraidSecondText(e.target.value)
                          }
                          // onKeyPress={handleKeyPress}
                        />

                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        <label
                          htmlFor="nira"
                          className="label-custom-form margin-top-35"
                        >
                          Enable Edit Mode
                        </label>
                        <Form.Select
                          size="lg"
                          className="custom-select select-type"
                          name="assetTypeLabeled2"
                          value={niraIdType3Value}
                          onChange={(e) => setNiraIdType3Value(e.target.value)}
                        >
                          <option key="true" value="true">
                            Yes
                          </option>
                          <option key="false" value="false">
                            No
                          </option>
                        </Form.Select>

                      </>
                    )}
                  </div>

                  <br />
                </div>
                <div className="">
                  <Button
                    variant="primary"
                    className="primary-btn margin-top-35"
                    onClick={handleSubmitProjectDetails}
                  >
                    Save Project Details
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ProjectDetails;
