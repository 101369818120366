import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from "react-router-dom";
import Header from '../components/header';
import { API_URL } from '../constants';
import Moment from 'moment';



const ProjectInvites = (props) => {

    let { id } = useParams();



    const [user, setUser] = useState(() => {
        const saved = localStorage.getItem('currentUser');
        const initialValue = JSON.parse(saved);
        return initialValue.user.email || "";
    });

    const [projectInvites, setProjectInvites] = useState([]);



    useEffect(() => {
        // setProjectId(id);
        async function getProjectInviteByProjectId() {
            const data_obj = {
                projectId: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectInviteByProjectId`;

            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr', hxr)
            if (hxr.data.success === true && hxr.data?.projects) {
                console.log('Invites', hxr.data.projects)
                setProjectInvites(hxr.data.projects);
            }
            // setAssets(hxr.data.assets)            
        }

        getProjectInviteByProjectId();

    }, [])


    const handleClick = () => {
        window.location = `/project/${id}/edit`
    }
    // console.log('selectedDamages', selectedDamages)
    return (
        <>
            <Header />
            <Container >
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard parameters">
                            <div className="profile-actions">
                                <div className="project-actions-left left">
                                    View Invites
                                </div>
                                <div className="project-actions-right right">
                                    <Button variant="btn primary-btn-active next-btn" onClick={handleClick}>
                                        Back
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className=" maxHeight450">
                                <Table striped bordered hover variant="dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                Invite for
                                            </th>
                                            <th>
                                                Invited Email
                                            </th>
                                            <th>
                                                Invite Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectInvites.map( (projectInvite, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {projectInvite.invitedEmail}
                                                    </td>
                                                    <td>
                                                        {projectInvite.userEmail}
                                                    </td>
                                                    <td>
                                                        {Moment(projectInvite.date).format('DD/MM/YYYY  h:mm:ss a')}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            <hr />
                            <div className="project-dashboard-footer">
                                
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default ProjectInvites;