import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Navbar,
  Nav,
  NavDropdown,
  ButtonGroup,
  ToggleButton,
  Form,
  Modal,
  Table
} from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Header from "../components/header";
import addInvite from "../images/add-invite.svg";
import addIconButton from "../images/addIconButton.svg";
import profilePlaceholder from "../images/user-profile-tools.svg";
import chevronImg from "../images/chevron.svg";
import deleteInviteImg from "../images/deleteInvite.svg";
import assetPlaceholder from "../images/projectDetails.svg";
import deleteImg from "../images/delete.svg";
import { API_URL, ASSET_DIR, RESULT_DIR } from "../constants";
import { useAuthState, useAuthDispatch, logout } from "../context";
import ProjectMenu from "../components/projectMenu";
import Moment from "moment";
import Thumbnail from "../components/thumbnails";
import FolderName from "../components/renderfolder";
import downloadIcon from "../images/downloadarrow.svg";
import { saveAs } from "../library/saveascustom";
import EditDetails from "../components/EditDetails";
import TopButtons from "../components/topButtons";
import { toLength } from "lodash";
import Dropzone from "../components/dropzone";

import ReactDOM from "react-dom";
import FolderTree from "react-folder-tree";
import useTreeState, { testData } from "use-tree-state";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';



const { uuid } = require("uuidv4");

const ConfigDeliverables = (props) => {
  let { id } = useParams();
  const [projectId, setProjectId] = useState("");
  const [projectInvites, setProjectInvites] = useState([]);
  const [isProjectGuest, setIsProjectGuest] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [invite, setInvite] = useState("");
  const handleShow = () => setShow(true);
  const [project, setProject] = useState([]);
  const [assets, setAssets] = useState([]);
  const [niraid, setNiraid] = useState([]);
  const [status, setStatus] = useState([]);

  const [folderName, setFolderName] = useState("");
  const [folderLevel, setFolderLevel] = useState(0);
  const [folderType, setFolderType] = useState("");
  const [folderId, setFolderId] = useState(""); // parent folderId
  const [parentFolderName, setParentFolderName] = useState("");
  const [hasAssetId, setHasAssetId] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [assetName, setAssetName] = useState("");
  const [isOrthoUpload, setIsOrthoUpload] = useState("");
  const [isCsvProcess, setIsCsvProcess] = useState("");

  const [expanded, setExpanded] = useState([]);
  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const [showEditFolder, setShowEditFolder] = useState(false);
  const [folderEditName, setFolderEditName] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [defaultFolderName, setDefaultFolderName] = useState("");

  const [ortholist, setOrtholist] = useState([]);


  const handleLogout = () => {
    window.location.href = "/login";
  };

  const handleEdit = (deliverableId) => {
    window.location.href = `/project/${id}/configDeliverables/${deliverableId}/details`;
  };
  const [email, setEmail] = useState(() => {
    const saved = localStorage.getItem("currentUser");
    const initialValue = JSON.parse(saved);
    return initialValue.user.email || "";
  });
  useEffect(() => {
    setProjectId(id);
    async function getProjectById() {
      const data_obj = {
        projectid: id,
      };
      console.log("data_obj", data_obj);
      const config = {
        headers: {
          "Content-Type": "x-www-form-urlencoded",
        },
      };
      const url = `${API_URL}/projects/getProjectById`;

      let hxr = await axios(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: data_obj,
      });
      setProject(hxr.data.project);
      setIsProjectGuest(email !== hxr.data.project.createdBy);
      setNiraid(hxr.data.project.niraid);
      setStatus(hxr.data.project.status); 
      

      if (hxr.data?.success){
        const threeData = {
            projectId: id,
          };
          
          const threeConfig = {
            headers: {
              "Content-Type": "x-www-form-urlencoded",
            },
          };
          const treeUrl = `${API_URL}/projects/deliverableAsset/getAllDeliverableAssetsByProjectId`;
    
          let treeHxr = await axios(treeUrl, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            data: threeData,
          });
          console.log('treeHxr',treeHxr)
          if (treeHxr.data?.success){
            // console.log('here', treeHxr.data)
            setOrtholist(treeHxr.data.deliverableAsset);
          }
      }

         
      
    }
    getProjectById();
  }, []);

  const handleClose = () => {
    setShow(false);
    setShowEmailError(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setIsInitialStep(true);
    setIsOrthoUpload(false);
    setIsCsvProcess(false);
  };
  const handleEditCloseModal = () => {
    showEditModal(false);
    setIsInitialStep(true);
    setIsOrthoUpload(false);
    setIsCsvProcess(false);
  };

  const handleCreate = (isConfig) => {
    window.location.href =`/project/${id}/configDeliverables/create`;
  };

  const [isFolder, setIsFolder] = useState(false);

  const folder = "null";
  const [isInitialStep, setIsInitialStep] = useState(true);

  const processModal = (isFolder) => {
    if (isFolder) {
      setShowModal(false);
    } else {
      setIsInitialStep(false);
    }
  };

  const handleOver = () => {
    console.log("over");
    setShowIcons(true);
  };

  const handleOut = () => {
    console.log("out");
    setShowIcons(false);
  };

  const hadnleSetParentFolder = (id) => {
    setParentFolder(id);
    console.log("parent folder", id);
  };

  const [parentFolder, setParentFolder] = useState("");

  const [treeData, setTreeData] = useState({
    id: "root",
    name: "",
    children: [],
  });

  const [showIcons, setShowIcons] = useState(false);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      level={nodes.level}
      onClick={() => {
        setFolderLevel(nodes.level);
        setFolderId(nodes.id);
        setParentFolderName(nodes.name);
        console.log("nodes.id", nodes.id);
        if (nodes.id == "root" || nodes.id == "deliverables") {
          setShowDeleteFolder(false);
          setShowEditFolder(false);
        } else {
          setShowDeleteFolder(true);
          setShowEditFolder(true);
        }
      }}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  function findDeep(array, id, node) {
    var object;

    array.some(function f(a) {
      if (a.id === id) {
        object = a;
        a.children.push(node);
        // setExpanded([id])
        return true;
      }
      if (Array.isArray(a.children)) {
        return a.children.some(f);
      }
    });
    return object;
  }
  const removeKey = (data, id) => {
    // look to see if object exists
    const index = data.findIndex((x) => x.id === id);
    if (index > -1) {
      data.splice(index, 1); // remove the object
      return true;
    } else {
      // loop over the indexes of the array until we find one with the id
      return data.some((x) => {
        if (x.children) {
          return removeKey(x.children, id);
        } else {
          return false;
        }
      });
    }
  };


  const handleDeleteOrtho = async (id) => {
    console.log('id', id)
    Swal.fire({
        title: 'Are you sure?',
        text: "This action cannot be undone",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#fdc20f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete!'
      }).then(async (result) => {
        if (result.isConfirmed) {
    
            const data_obj = {
                id: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/deliverableAsset/deleteDeliverableAssetById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })
            console.log('hxr',hxr.data)
            document.getElementById(`asset-${id}`).remove()
            Swal.fire(
                'Deleted!',
                'Your asset has been deleted.',
                'success'
              )
        }
      })
  }

  
  
  return (
    <>     
      <Header />
      <Container>
        <Row>
          <Col md="12">
            <form className="action-link-auth">
              <div className="project-dashboard">
                <div className="profile-actions">
                  <div className="project-actions-left left padding10px">
                    <Link className="no-link" to="/dashboard">
                      All Projects
                    </Link>{" "}
                    <span>
                      {" "}
                      <img src={chevronImg} width="15px" />{" "}
                    </span>{" "}
                    {project.name}
                  </div>
                  <div className="project-actions-right right">
                    <TopButtons id={id} />
                  </div>
                </div>
                <br />
                <hr />

                <div className="project-left edit-assets w100">
                  <ProjectMenu active="config" id={id} />
                  <div className="addIcon">
                    <a
                      onClick={() => {
                        handleCreate();
                      }}
                    >
                      Add New Orthomosaic{" "}<AddCircleIcon /> 
                    </a>
                  </div>
                  <div className="listOrtho">
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Created Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ortholist.map((ortho, idx) => (
                        <tr id={`asset-${ortho._id}`}>
                          <td>{idx+1}</td>
                          <td width="60%">{ortho.name}</td>
                          <td>{ortho.createdDate}</td>
                          <td className="ortho-list-actions">
                            <a onClick={()=>handleEdit(ortho._id)}>
                              <EditIcon /> 
                            </a>
                            <a onClick={()=>handleDeleteOrtho(ortho._id)}>
                              <HighlightOffIcon />
                            </a>
                          </td>
                        </tr> 
                      ))}
                                           
                    </tbody>
                  </Table>
                  </div>
                  <div className="editWrapper">
                    <div className="assetsContainer project-left w100"></div>
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ConfigDeliverables;
