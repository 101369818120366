import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Navbar, Nav, NavDropdown, ButtonGroup,ToggleButton,Form } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { API_URL, ASSET_DIR, RESULT_DIR } from '../constants';
import axios from 'axios';

const EditDetails = (props) => {

    const [assets, setAssets] = useState([]);
    const [niraid, setNiraid] = useState([]);
    const [labeledNiraId, setLabeledNiraid] = useState([]);
    const [labeledNiraIdSecond, setLabeledNiraidSecond] = useState([]);
    const [labeledNiraIdText, setLabeledNiraidText] = useState([]);
    const [labeledNiraIdSecondText, setLabeledNiraidSecondText] = useState([]);
    const [status, setStatus] = useState([]);
    const [projectId, setProjectId] = useState("");

    let { id } = useParams(); 

    useEffect(() => {
        setProjectId(id);
        async function getProjectById() {
            const data_obj = {
                projectid: id
            }
            console.log('data_obj', data_obj);
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getProjectById`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                data: data_obj
            })        
            
            setNiraid(hxr.data.project.niraid)
            setStatus(hxr.data.project.status)
            setLabeledNiraid(hxr.data.project.labeledNiraId)
            setLabeledNiraidSecond(hxr.data.project.labeledNiraIdSecond)
        }
        getProjectById();
    }, [])

    const handleSubmitProjectDetails = async (e) => {        
        e.preventDefault();

        try{
            const data = {
                projectid: id,
                niraid: niraid,
                status: status,
                labeledNiraId: labeledNiraId
              }
              console.log('data',data)
            const config = {
                method: 'post',
                url: `${API_URL}/projects/updateProjectStatusAndNiraId`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                data: data
                }
            
            const xhr = await axios(config).catch(error => {
                console.log('error', error);
                throw error;
            });

            console.log('save project',xhr)

            if(xhr && xhr.data.success){
                
            }
            
        }catch(e){
            console.log('error', e)
        }

        
    }
    
    return (
        <>
            <h4>Edit Project Details</h4>                                            
                <form>
                <br />
                <br />
                <label htmlFor="labeledNira" className="label-custom margin-top-35">Labeled 3D Model</label>
                    <input 
                        type="text" 
                        name="labeledNira" 
                        id="labeledNira" 
                        className="email input " 
                        placeholder="-"
                        required value={labeledNiraId} onChange={(e) => setLabeledNiraid(e.target.value)} 
                        // onKeyPress={handleKeyPress}
                    />
                    <br />
                    <br />
                    <label htmlFor="nira" className="label-custom margin-top-35">Labeled 3D Model No.2</label>
                    <input 
                        type="text" 
                        name="labeledNiraIdSecond" 
                        id="labeledNiraIdSecond" 
                        className="email input" 
                        placeholder="-"
                        required value={labeledNiraIdSecond} onChange={(e) => setLabeledNiraidSecond(e.target.value)} 
                        // onKeyPress={handleKeyPress}
                    />
                    <br />
                    <br />
                    
                    <label htmlFor="nira" className="label-custom margin-top-35">Nira Id</label>
                    <input 
                        type="text" 
                        name="nira" 
                        id="nira" 
                        className="email input" 
                        placeholder="-"
                        required value={niraid} onChange={(e) => setNiraid(e.target.value)} 
                        // onKeyPress={handleKeyPress}
                    />
                    <br />
                    <br />
                    
                    <label htmlFor="status" className="label-custom">Status</label>
                    <Form.Select 
                            size="lg" 
                            className="custom-select" 
                            name="assetType" 
                            value={status} onChange={(e) => setStatus(e.target.value)}
                    >
                        <option>Status</option>
                        <option value="active">Active</option>
                        <option value="in progress">In Progress</option>
                        <option value="hold">Hold</option>
                        <option value="draft">Draft</option>
                        <option value="done">Done</option>
                        <option value="submitted">Submitted</option>
                        <option value="completed">Completed</option>
                    </Form.Select>
                    {/* <p>Forgot Password? <Link to="/forgotpassword">Reset now</Link></p> */}
                    <Button 
                        variant="primary" 
                        className="primary-btn margin-top-35"
                        onClick={handleSubmitProjectDetails} 
                    >Save Project Details</Button>
                </form>
        </>
    )
}
export default EditDetails;

    