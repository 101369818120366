import React, { useEffect, useState,memo } from "react";
import { API_URL, ASSET_DIR, RESULT_DIR } from '../constants';
import assetPlaceholder from '../images/projectDetails.svg';


const Folder = (props) => {


    if(props.foldername === null || props.foldername === undefined || props.foldername === ''){
        return <span></span>;
    }

    const folderNameStr = {
        images360: 'Images - 360 degree',
        imagesFishLens: 'Images - Fisheye lens',
        imagesTypical: 'Images - Regular',
        videos360: 'Videos - 360 degree',
        videosFishLens: 'Videos - Fisheye lens',
        videosTypical: 'Videos - Regular',
        laserScan: 'Laser Scan',
        infrared: 'Infrared',            
    }
    
    return <span> {folderNameStr[props.foldername]} / </span>;
}    
export default memo(Folder);

    