import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from '../src/config/routes';
import { AuthProvider,useAuthState } from '../src/context';
import AppRoute from '../src/components/appRoutes';
import Header from '../src/components/header';

// import { AuthProvider } from "./context";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  
	return (
    <div>
		<AuthProvider>
			<Router>
				<Switch>
					{routes.map((route) => (
						<AppRoute
              exact
							key={route.path}
							path={route.path}
							component={route.component}
							isPrivate={route.isPrivate}
						/>
					))}
				</Switch>
			</Router>
		</AuthProvider>
    </div>
	);
}
export default App;