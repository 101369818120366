import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Navbar, Nav, NavDropdown, Table } from 'react-bootstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import Header from '../components/header';
import { API_URL, STATUS } from '../constants';
import RenderImage from '../components/renderImages';
import Moment from 'moment';
import { FilterStatus, FlatArray, GetCurrentStatus, GetStatusName } from '../utilities';

const Dashboard = (props) => {
    
    const handleProject = async (e) => {
        e.preventDefault()
        props.history.push('/project') //navigate to dashboard on success
    }
    const handleEdit = async (id) => {
        // e.preventDefault()
        props.history.push(`/project/${id}/edit`) //navigate to dashboard on success
    }

    const [projects, setProjects] = useState([]);
    const [invitedProjects, setinvitedProjects] = useState([]);
    const [filters, setFilters] = useState({});

    const saved = localStorage.getItem('currentUser');
    const initialValue = JSON.parse(saved);
    const currentUser  = initialValue.user.email;

    const [status, setStatus] = useState(STATUS.DRAFT);

    const handleStatusChange = async (e) => {
        let status = e.target.value;
        if(e.target.value === 'all'){
            status = {};
        }else{
            status = {status: e.target.value};
        };
        console.log('status', status);
        const url = `${API_URL}/projects/getAllProjects`;

        let hxr = await axios(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            data: status
        })
        console.log('setProjects hxr',hxr)
        setProjects(hxr.data.projects)
    }

    useEffect(() => {
        async function getAllProjects() {
            
            const config = {
                headers: {
                    'Content-Type': 'x-www-form-urlencoded',
                },
            };
            const url = `${API_URL}/projects/getAllProjects`;
    
            let hxr = await axios(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                }
            })
            console.log('setProjects hxr',hxr)
            setProjects(hxr.data.projects)
        }

        getAllProjects();

    }, [])

    

    
// <option value="1">Bridge</option>
// <option value="2">Building Facade</option>
// <option value="3">Cooling Tower</option>
// <option value="4">Industrial Chimney</option>
// <option value="5">Dam</option>
// <option value="6">Levee</option>
// <option value="7">Culvert</option>

    const parseAssetType = (assetId) => {
        let type = '-';
        switch(assetId){
            case '1':
                type = 'Bridge';
                break; 
            case '2':
                type = 'Building Facade';
            break; 
            case '3':
                type = 'Cooling Tower';
            break; 
            case '4':
                type = 'Industrial';
                break; 
            case '5':
                type = 'Dam';
                break; 
            case '6':
                type = 'Levee';
                break; 
            case '7':
                type = 'Culvert';
            case '8':
                type = 'Tunnel';    
                break;   
            case '9':
                type = 'Runway';
                break;   
            case '10':
                type = 'Road';
                break;   
            case '11':
                type = 'Wind Turbine';
                break;         
               
        }
        return type;
    }

    return (
        <>
            <Header />
            <Container >
                {/* <div className="dashboard-banner">
                    <div className="logo">
                        
                    </div>
                    <div className="alerts"></div>
                    <div className="profile"></div>
                </div> */}
                <Row>
                    <Col md="12" >
                        <div className="project-dashboard">
                        <div className="project-dashboard-actions">
                                <div className="project-actions-left left padding10px"> 
                                    All Projects 
                                 </div>
                                <div className="project-actions-right right">
                                    {/* <Button variant="btn primary-btn-active next-btn" onClick={handleProject}  >
                                        New Project
                                    </Button> */}
                                </div>
                        </div>
                        <div>
                            {/* <Col xs="8"></Col> */}
                            <Col md={{ span: 3, offset: 9 }}>
                                <Form.Select size="lg" className="custom-select" name="status"  value={status} onChange={(e) => handleStatusChange(e)}>
                                <option value="all">All</option>
                                {STATUS.map((item, index) => {
                                            return item.type.includes('admin') ? (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            ) : (
                                            ''
                                            );
                                        })}
                                </Form.Select>
                            </Col>  
                            <br />
                            <hr />
                            <br />
                        </div>
                            {projects && projects.length > 0 ? (
                            <Table>
                                <thead>
                                    <tr className="project-rows">
                                        <th>Project Name</th>
                                        <th>Project Status</th>
                                        <th>Asset Type</th>
                                        <th>Date Created</th>
                                    </tr>
                                </thead>
                                <tbody className="project-tablebody">
                                
                                   { projects.map(project=>{
                                        return(
                                            <tr className="clickable" onClick={()=>handleEdit(project._id)}>
                                                <td>
                                                    <div className="left w25">
                                                        <RenderImage  projectType={project.assetsType} size="125"/>
                                                    </div>
                                                    <div className="left w50">
                                                        <span className="project-title">{project.name}</span> < br/>
                                                        <span className="project-location">{project.address}, {project.city}, {project.state}, {project.country}</span>
                                                    </div>
                                                    
                                                </td>
                                                <td><span className="projectStatus">{ GetStatusName(project.status)}</span></td>
                                                <td>{parseAssetType(project.assetsType)}</td>
                                                <td>{Moment(project.createdDate).format('D, MMMM yy')}</td>
                                            </tr>
                                        )
                                    })}
                              
                                </tbody>
                            </Table>
                            ) : ""}

                            
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Dashboard;
